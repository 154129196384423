import React, { Component } from 'react';
import Loader from './loader';
import Picture from './picture';

class LazyImage extends Component {
  state = { imageLoaded: false };

  render() {
    const { svg, src, ext, alt } = this.props;

    return (
      <React.Fragment>
        <Loader style={this.state.imageLoaded ? { opacity: 0 } : {}} image={svg} />
        <Picture
          src={src}
          ext={ext}
          alt={alt}
          style={this.state.imageLoaded ? { opacity: 1 } : {}}
          onLoad={() => this.setState({ imageLoaded: true })}
        />
      </React.Fragment>
    );
  }
}

export default LazyImage;
