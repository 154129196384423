import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import ImageGallery from '../common/blocks/imageGallery';
import { getPartner, getFrenchPartner } from '../../services/partnerService';
import Logo from '../common/blocks/logo';
import PartnerHighlight from '../common/blocks/partnerHighlight';
import Contact from '../common/blocks/contact';
import Map from '../common/blocks/map';
import '../../styles/partners.module.scss';
import '../../styles/notification.module.scss';

import { withRouter, Link, Redirect } from 'react-router-dom';

class Partners extends Component {
  state = {
    lang: this.props.lang,
    size: 'small',
    showNotification: true,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.resized);
    this.resized();
    setTimeout(() => this.setState({ showNotification: false }), 7500);
  };

  componentDidUpdate() {
    // const { lang } = this.state;
    // if (lang === 'fr' && !this.props.history.location.pathname.includes('/fr'))
    //   this.props.history.replace(`/fr/${this.props.match.params.alias}`);
    // if (lang === 'nl' && this.props.history.location.pathname.includes('/fr'))
    //   this.props.history.replace(`/${this.props.match.params.alias}`);
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resized);
  };

  resized = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      this.setState({ size: 'small' });
    } else if (innerWidth >= 768 && innerWidth < 992) {
      this.setState({ size: 'medium' });
    } else {
      this.setState({ size: 'large' });
    }
  };

  getSize = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      return { width: '100%', height: (innerWidth / 3) * 2 * 6 };
    } else if (innerWidth >= 768 && innerWidth < 992) {
      return { width: '100%', height: (innerWidth / 6) * 2 * 5 };
    } else {
      return { width: '100%', height: (innerWidth / 9) * 2 * 5 };
    }
  };

  getImages = ({ alias, numOfImages }) => {
    const images = [];
    for (let i = 0; i < numOfImages; i++) {
      images[i] = {
        original: `${alias}/slideshow/${i}`,
      };
    }
    return images;
  };

  changeLanguage = (lang) => {
    const { alias } = this.props.match.params;
    if (lang === 'nl') {
      this.props.history.replace(`/${alias}`);
      this.setState({ lang: 'nl', partner: getPartner(alias), showNotification: true });
    } else {
      this.props.history.replace(`/fr/${alias}`);
      this.setState({ lang: 'fr', partner: getFrenchPartner(alias), showNotification: true });
    }
  };

  render() {
    console.log(this.props);
    const { params } = this.props.match;

    let partner = getPartner(params.alias);
    if (this.state.lang === 'fr') {
      if (partner.french) partner = getFrenchPartner(params.alias);
      else {
        this.setState({ lang: 'nl' });
        return <Redirect to={`/${params.alias}`} />;
      }
    }

    const { alias, name, logo, description, hours, contact, googleQuerry, french, numOfImages, notification } = partner;
    return (
      <React.Fragment>
        <Helmet>
          <title>{name} &#124; Landen-online</title>
        </Helmet>

        {french && (
          <div className='languageButton'>
            <p className={this.state.lang === 'nl' ? 'active' : ''} onClick={() => this.changeLanguage('nl')}>
              NL
            </p>
            <div className='bar'></div>
            <p className={this.state.lang === 'fr' ? 'active' : ''} onClick={() => this.changeLanguage('fr')}>
              FR
            </p>
          </div>
        )}
        <Link to={'/'} className='homeButton'>
          <p className='partner-text'>Home</p>
        </Link>

        {notification && (
          <div className={`partner-notification ${!this.state.showNotification ? 'partner-notification-hidden' : ''}`}>
            <div className='partner-notification-inner'>
              <div className='button' onClick={() => this.setState({ showNotification: false })}>
                <div className='bar'></div>
                <div className='bar'></div>
              </div>
              <p className='small-title heavy margin'>{notification.title}</p>
              {notification.content}
              <div className='progress'></div>
            </div>
          </div>
        )}

        <div className='main' style={this.getSize()}>
          <div className='left-container'>
            <Logo src={`${alias}/logo`} alt={`${name} Logo`} svg={logo} />
            <PartnerHighlight>{description}</PartnerHighlight>
          </div>
          <div className='mid-container'>
            <ImageGallery images={this.getImages({ alias, numOfImages })} />
          </div>
          <div className='right-container'>
            <PartnerHighlight light={true}>
              <p className='large-title heavy margin blue'>
                {this.state.lang === 'fr' ? "Heures d'ouverture" : 'Openingsuren'}
              </p>
              {hours}
            </PartnerHighlight>
            <Contact image={require(`../../images/${alias}/banner.jpg`)} data={contact} />
            <PartnerHighlight light={true} onClick={() => this.props.history.push('/')} style={{ cursor: 'pointer' }}>
              <p className='small-title heavy margin blue'>Lees ook ...</p>
            </PartnerHighlight>
            <LazyLoad offset={10}>
              <Map q={googleQuerry} />
            </LazyLoad>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Partners);
