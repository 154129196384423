import React, { Component } from 'react';
import Loader from '../loader';
import Picture from './../picture';
import { Header } from '../../../images/sqip';
import styles from '../../../styles/top.module.scss';

class Top extends Component {
  state = { imageLoaded: false };

  render() {
    const { size } = this.props;

    return (
      <div className='block' style={{ width: size.width, height: size.height }}>
        <div className={styles.container}>
          <div className={styles.background}>
            <Loader style={this.state.imageLoaded ? { opacity: 0 } : {}} image={<Header />} />
            <Picture
              src='header'
              ext='png'
              alt='Straatbeeld Landen'
              style={this.state.imageLoaded ? { opacity: 1 } : {}}
              onLoad={() => this.setState({ imageLoaded: true })}
            />
          </div>
          <div className={styles.content}>
            <p className='title small-margin'>Landen-Online</p>
            <p className='subtitle'>Zoek en vind handelaars in uw stad</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Top;
