import React from 'react';
import Form from './form';
import Joi from 'joi-browser';
import _ from 'lodash';
import { toast } from 'react-toastify';
import http from '../../services/httpService';

import styles from '../../styles/form.module.scss';

class ContactForm extends Form {
  state = {
    data: {
      name: '',
      mail: '',
      company: '',
      phone: '',
      message: ''
    },
    errors: {},
    submitted: false,
    loading: false
  };

  schema = {
    name: Joi.string()
      .min(2)
      .required(),
    mail: Joi.string().email({ minDomainSegments: 2, tlds: false }),
    company: Joi.string().allow(''),
    phone: Joi.string().allow(''),
    message: Joi.string()
      .required()
      .min(10)
  };

  clearData() {
    this.setState({
      data: {
        name: '',
        mail: '',
        company: '',
        phone: '',
        message: ''
      },
      errors: {},
      submitted: true
    });
  }

  doSubmit = async () => {
    this.setState({ loading: true });
    const apiUrl = 'https://lnt-mail-backend.herokuapp.com/api/mail/landenonline';
    try {
      console.log(this.state.data);
      const request = await http.post(apiUrl, { token: 'kbhdBXsH5FFkX5F0i4qV', ...this.state.data });
      if (request) console.log(request);

      toast.success(
        <React.Fragment>
          <p style={{ marginBottom: '5px' }}>
            <strong>Gelukt!</strong> Het contactformulier is verzonden.
          </p>
        </React.Fragment>
      );

      this.clearData();
    } catch (err) {
      toast.error(
        <React.Fragment>
          <p style={{ marginBottom: '5px' }}>
            <strong>Oeps!</strong> Het verzenden van het contactformulier is niet gelukt.
          </p>
          <p style={{ marginBottom: '5px' }}>
            <strong>{err.toString()}</strong>
          </p>
        </React.Fragment>
      );
    }

    this.setState({ loading: false });
  };

  reloadForm = () => {
    this.setState({ submitted: false });
  };

  getMessageStyle = () => {
    const { errors, submitted } = this.state;
    let style = styles.formMessage;
    if (!_.isEmpty(errors) || submitted) style += ' ' + styles.show;
    if (!_.isEmpty(errors)) style += ' ' + styles.danger;
    if (submitted) style += ' ' + styles.success;
    return style;
  };

  getMessageContent = () => {
    const { errors, submitted } = this.state;
    if (!_.isEmpty(errors))
      return (
        <React.Fragment>
          <strong className={styles.strong}>Opgelet!</strong> Er zijn problemen met het contactformulier.
        </React.Fragment>
      );
    if (submitted)
      return (
        <React.Fragment>
          <strong className={styles.strong}>Succes!</strong> Uw bericht werd verstuurd.
        </React.Fragment>
      );
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={styles.form}>
        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            {this.renderInput('name', 'text', { placeholder: 'Naam + Familienaam', className: styles.input })}
          </div>
          <div className={styles.inputContainer}>
            {this.renderInput('mail', 'text', { placeholder: 'E-mail', className: styles.input })}
          </div>
        </div>
        <div className={styles.formRow}>
          <div className={styles.inputContainer}>
            {this.renderInput('company', 'text', { placeholder: 'Bedrijf', className: styles.input })}
          </div>
          <div className={styles.inputContainer}>
            {this.renderPhoneInput('phone', {
              placeholder: 'Tel./Gsm'
            })}
          </div>
        </div>
        <div className={styles.bottom + ' ' + (this.state.errors['message'] ? styles.bottomError : '')}>
          {this.renderTextarea('message', { placeholder: 'Bericht', className: styles.textArea })}
          {this.renderButton('Verstuur', { className: styles.button, onClick: this.handleSubmit })}
        </div>
      </form>
    );
  }
}

export default ContactForm;
