import React from 'react';

const Input = ({ name, error, ...rest }) => {
  return (
    <React.Fragment>
      <input {...rest} name={name} id={name} style={error ? { border: '1px solid red', color: 'red' } : {}} />
    </React.Fragment>
  );
};

export default Input;
