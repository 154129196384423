import React from 'react';

export const Header = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1000 667'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#384e81' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(3.906250) translate(0.5 0.5)'>
          {' '}
          <g transform='translate(169.511636 106.531852) rotate(272.284783) scale(25.776112 51.948718)'>
            <ellipse fill='#ff9000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(138.373679 157.511873) rotate(267.324169) scale(31.154260 254.674452)'>
            <ellipse fill='#070000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(185.996659 54.105965) rotate(0.788521) scale(255.000000 32.684259)'>
            <ellipse fill='#2f83ff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(159.615363 103.612541) rotate(284.083306) scale(23.644153 40.309679)'>
            <ellipse fill='#ff8136' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(91 95) rotate(82) scale(46 55)'>
            <rect fill='#3474ee' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(12.845234 148.212021) rotate(194.187592) scale(51.153025 21.678080)'>
            <ellipse fill='#000400' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(89.246549 8.795211) rotate(270.718467) scale(17.738646 194.666636)'>
            <ellipse fill='#01287a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(237.133000 72.942294) rotate(275.898565) scale(22.221759 50.114529)'>
            <ellipse fill='#5197f6' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(224.891139 148.144013) rotate(265.304041) scale(46.054053 20.926818)'>
            <ellipse fill='#1e180a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(100.268602 135.120889) rotate(286.568224) scale(14.850474 12.057429)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#577dd5' fillOpacity='0.501961' x='27' y='128' width='58' height='7' />{' '}
          <g transform='translate(255 121) rotate(304) scale(34 19)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#e4783a' fillOpacity='0.501961' x='120' y='83' width='56' height='47' />{' '}
          <g transform='translate(6 23) rotate(46) scale(41 106)'>
            <rect fill='#12275c' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(76.091311 58.708811) rotate(284.635628) scale(36.997756 60.298488)'>
            <ellipse fill='#306bd9' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#e46a26'
            fillOpacity='0.501961'
            points='204.087565,87.848297,232.271547,95.258778,186.910418,124.960295,193.640093,127.359443'
          />{' '}
          <ellipse fill='#111408' fillOpacity='0.501961' cx='132' cy='149' rx='16' ry='28' />{' '}
          <polygon
            fill='#3577fa'
            fillOpacity='0.501961'
            points='259.350569,81.849086,231.501607,93.850477,237.473521,118.731349,244.563355,118.032806'
          />{' '}
          <g transform='translate(237.440691 27.301453) rotate(64.504592) scale(13.333915 112.989167)'>
            <ellipse fill='#265ed6' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#3d6acc' fillOpacity='0.501961' cx='22' cy='112' rx='17' ry='15' />{' '}
          <polygon fill='#8cd6fd' fillOpacity='0.501961' points='68,56 89,45 100,57' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='126' width='23' height='20' />{' '}
          <rect fill='#445f9c' fillOpacity='0.501961' x='30' y='125' width='50' height='13' />{' '}
          <polygon
            fill='#100a00'
            fillOpacity='0.501961'
            points='160.666077,142.093208,78.063096,146.281765,102.387154,111.238292,113.453453,135.809988'
          />{' '}
          <g transform='translate(0.000000 86.056611) rotate(100.262017) scale(18.888892 65.110272)'>
            <ellipse fill='#133d8d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#353424' fillOpacity='0.501961' cx='170' cy='151' rx='34' ry='20' />{' '}
          <g transform='translate(114 122) rotate(338) scale(11 24)'>
            <rect fill='#6f77b3' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#292f3c'
            fillOpacity='0.501961'
            points='63.603520,111.664800,68.586082,122.611146,34.637118,133.265440,49.241584,103.329689'
          />{' '}
          <g transform='translate(166 41) rotate(344) scale(86 13)'>
            <rect fill='#3984e9' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#080600' fillOpacity='0.501961' x='22' y='139' width='53' height='10' />{' '}
          <polygon fill='#254eb1' fillOpacity='0.501961' points='223,17 70,16 120,45' />{' '}
          <polygon fill='#6878c2' fillOpacity='0.501961' points='96,119 67,111 71,137' />{' '}
          <g transform='translate(166 85) rotate(9) scale(51 5)'>
            <rect fill='#a14712' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#f88743'
            fillOpacity='0.501961'
            points='154.868103,132.986728,140.222838,78.332584,121.817830,104.877018,119.429709,115.958501'
          />{' '}
          <polygon
            fill='#4080dc'
            fillOpacity='0.501961'
            points='151.627538,68.478845,261.321271,37.037794,246.413154,94.667032,156.051573,80.664361'
          />{' '}
          <rect fill='#ec8947' fillOpacity='0.501961' x='163' y='86' width='14' height='47' />{' '}
          <rect fill='#8f481f' fillOpacity='0.501961' x='173' y='108' width='15' height='19' />{' '}
          <polygon fill='#2858c1' fillOpacity='0.501961' points='198,40 125,85 21,79' />{' '}
          <polygon fill='#5b3b21' fillOpacity='0.501961' points='176,140 231,96 234,120' />{' '}
          <rect fill='#ff9c53' fillOpacity='0.501961' x='187' y='92' width='11' height='30' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='255' cy='124' rx='21' ry='6' />{' '}
          <g transform='translate(237.168151 163.324307) rotate(273.618297) scale(6.760933 29.442307)'>
            <ellipse fill='#030d15' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(100.430042 2.674869) rotate(176.626185) scale(136.019801 11.951539)'>
            <ellipse fill='#172f62' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#a15f39' fillOpacity='0.501961' x='151' y='87' width='13' height='45' />{' '}
          <rect fill='#4f8be1' fillOpacity='0.501961' x='75' y='87' width='45' height='11' />{' '}
          <g transform='translate(118.048608 167.806793) rotate(275.002288) scale(19.995131 86.337391)'>
            <ellipse fill='#1c2735' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(0.000826 85.879451) rotate(255.088158) scale(20.573635 9.452126)'>
            <ellipse fill='#1a2d4e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(255 106) rotate(186) scale(5 25)'>
            <rect fill='#010100' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#dea2aa' fillOpacity='0.501961' cx='232' cy='114' rx='3' ry='3' />{' '}
          <rect fill='#0f1607' fillOpacity='0.501961' x='222' y='99' width='8' height='35' />{' '}
          <rect fill='#f08a41' fillOpacity='0.501961' x='210' y='91' width='10' height='14' />{' '}
          <ellipse fill='#1f4ab3' fillOpacity='0.501961' cx='233' cy='25' rx='45' ry='7' />{' '}
          <polygon fill='#ba5b2a' fillOpacity='0.501961' points='214,120 203,89 197,121' />{' '}
          <polygon
            fill='#375592'
            fillOpacity='0.501961'
            points='53.510559,129.945673,31.063509,142.045193,27.538054,138.763903,57.148893,115.508410'
          />{' '}
          <g transform='translate(61.786240 116.313382) rotate(92.742430) scale(32.080970 1.000000)'>
            <ellipse fill='#11202e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(133.184815 131.132623) rotate(321.121868) scale(7.624539 10.531743)'>
            <ellipse fill='#150900' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#4668b6' fillOpacity='0.501961' points='35,126 -16,120 47,100' />{' '}
          <polygon
            fill='#214aac'
            fillOpacity='0.501961'
            points='53.305411,18.780685,-16.000000,78.358774,19.590967,28.742205,-10.463210,25.776002'
          />{' '}
          <rect fill='#030701' fillOpacity='0.501961' x='88' y='124' width='25' height='22' />{' '}
          <polygon fill='#0b0b00' fillOpacity='0.501961' points='75,146 18,144 46,135' />{' '}
          <polygon
            fill='#e66723'
            fillOpacity='0.501961'
            points='124.287638,89.674288,149.234677,80.931439,120.815958,86.742557,121.427946,115.215209'
          />{' '}
          <rect fill='#010000' fillOpacity='0.501961' x='0' y='128' width='25' height='19' />{' '}
          <polygon fill='#151e2d' fillOpacity='0.501961' points='211,167 184,133 271,130' />{' '}
          <polygon
            fill='#f99c58'
            fillOpacity='0.501961'
            points='155.834097,102.485624,170.451627,87.186208,172.219350,121.100843,169.903452,110.310718'
          />{' '}
          <polygon fill='#4a63a3' fillOpacity='0.501961' points='81,137 88,125 40,133' />{' '}
          <polygon
            fill='#28292b'
            fillOpacity='0.501961'
            points='51.349222,111.501234,46.918933,98.718792,59.813776,111.559985,35.154616,125.633719'
          />{' '}
          <polygon
            fill='#182538'
            fillOpacity='0.501961'
            points='-15.985791,100.675457,14.813224,115.901182,-16.000000,112.812436,13.727572,86.376899'
          />{' '}
          <rect fill='#4d67b6' fillOpacity='0.501961' x='112' y='94' width='9' height='36' />{' '}
          <polygon
            fill='#3d6ccf'
            fillOpacity='0.501961'
            points='231.780128,87.269503,271.000000,75.385369,237.876676,113.484403,232.442006,111.323153'
          />{' '}
          <polygon
            fill='#2c67d4'
            fillOpacity='0.501961'
            points='176.515650,59.872220,271.000000,15.436366,255.742930,-11.161292,237.088858,49.322955'
          />{' '}
          <polygon
            fill='#1a2627'
            fillOpacity='0.501961'
            points='51.645294,92.379293,51.498218,121.623929,33.412497,112.220280,48.910890,113.604638'
          />{' '}
          <polygon
            fill='#3c86e1'
            fillOpacity='0.501961'
            points='63.250927,37.163897,50.800686,35.527425,62.888142,23.569429,111.268652,40.444679'
          />{' '}
          <ellipse fill='#ffba66' fillOpacity='0.501961' cx='78' cy='87' rx='2' ry='2' />{' '}
          <polygon
            fill='#535f8c'
            fillOpacity='0.501961'
            points='79.060265,132.288974,110.719853,113.517756,89.261313,104.140096,80.563487,150.623386'
          />{' '}
          <polygon
            fill='#316ed6'
            fillOpacity='0.501961'
            points='-5.518383,55.264494,34.679433,74.566882,57.399782,93.011092,99.228714,57.006073'
          />{' '}
          <polygon fill='#387bdc' fillOpacity='0.501961' points='203,43 86,60 153,29' />{' '}
          <polygon
            fill='#193770'
            fillOpacity='0.501961'
            points='271.000000,152.641227,242.810841,152.775498,240.127489,132.267097,271.000000,170.300684'
          />{' '}
          <g transform='translate(41 123) rotate(251) scale(5 4)'>
            <rect fill='#940000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#443a31' fillOpacity='0.501961' x='212' y='103' width='9' height='30' />{' '}
          <polygon
            fill='#264c9a'
            fillOpacity='0.501961'
            points='61.684974,99.903152,9.204464,100.684922,-16.000000,100.251519,76.406683,86.127733'
          />{' '}
          <g transform='translate(190.464162 137.470969) rotate(353.773184) scale(58.378755 1.109320)'>
            <ellipse fill='#191900' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#944f2b' fillOpacity='0.501961' x='172' y='94' width='12' height='9' />{' '}
          <g transform='translate(181 89) rotate(9) scale(100 1)'>
            <rect fill='#a34c14' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#ea8546'
            fillOpacity='0.501961'
            points='123.237234,101.226926,150.077559,81.013848,150.141129,134.326845,138.161364,125.631279'
          />{' '}
          <g transform='translate(90.373767 110.508303) rotate(262.881088) scale(5.354016 28.027140)'>
            <ellipse fill='#2d549e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#2854b9' fillOpacity='0.501961' points='24,62 -5,87 65,74' />{' '}
          <g transform='translate(168 97) rotate(191) scale(39 2)'>
            <rect fill='#e8986a' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#e4864b'
            fillOpacity='0.501961'
            points='172.067449,108.876090,138.775730,104.846667,172.918609,119.019597,149.059843,117.288176'
          />{' '}
          <polygon
            fill='#564845'
            fillOpacity='0.501961'
            points='182.278540,156.190462,199.516374,158.466221,185.858330,141.875588,188.047487,138.664482'
          />{' '}
          <g transform='translate(121.015146 30.586496) rotate(123.451802) scale(24.742427 5.576095)'>
            <ellipse fill='#254b9f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#854926'
            fillOpacity='0.501961'
            points='151.248426,115.439661,159.550588,137.856973,162.959471,137.383602,165.422247,127.685984'
          />{' '}
          <rect fill='#011713' fillOpacity='0.501961' x='178' y='126' width='14' height='4' />{' '}
          <g transform='translate(200 109) rotate(82) scale(47 1)'>
            <rect fill='#4e2e24' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#446dc9' fillOpacity='0.501961' x='9' y='107' width='40' height='5' />{' '}
          <rect fill='#ffe7cd' fillOpacity='0.501961' x='145' y='86' width='5' height='3' />{' '}
          <polygon
            fill='#e67832'
            fillOpacity='0.501961'
            points='167.287374,109.927299,180.363989,131.332713,178.414246,132.151585,171.407347,132.302014'
          />{' '}
          <polygon
            fill='#1d418d'
            fillOpacity='0.501961'
            points='235.591341,30.206984,231.826198,29.703660,265.012127,-9.779351,211.996757,22.966835'
          />{' '}
          <rect fill='#5e3117' fillOpacity='0.501961' x='121' y='118' width='4' height='23' />{' '}
          <polygon fill='#ff7e6f' fillOpacity='0.501961' points='134,124 134,127 132,124' />{' '}
          <ellipse fill='#393d46' fillOpacity='0.501961' cx='79' cy='160' rx='7' ry='18' />{' '}
        </g>
      </g>
    </svg>
  );
};

export const Atelier5Banner = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 886 591'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#848484' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(3.460938) translate(0.5 0.5)'>
          {' '}
          <g transform='translate(32.553637 45.534497) rotate(264.129432) scale(57.380337 71.891379)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#1b1b1b' fillOpacity='0.501961' cx='150' cy='169' rx='56' ry='186' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='255' cy='63' rx='24' ry='48' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='37' cy='33' rx='51' ry='51' />{' '}
          <g transform='translate(213.411871 35.730330) rotate(69.549924) scale(25.226385 9.595231)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(146.286571 21.816368) rotate(331.384696) scale(50.477155 13.776465)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='-10.549265,177.120237,21.387161,133.814036,32.791285,141.487719,19.728594,167.779812'
          />{' '}
          <g transform='translate(191.164361 103.219759) rotate(53.700188) scale(81.027496 39.750469)'>
            <ellipse fill='#626262' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(250.129955 56.919593) rotate(93.104587) scale(41.245378 14.138828)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(222 4) rotate(211) scale(53 32)'>
            <rect fill='#292929' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#151515' fillOpacity='0.501961' cx='117' cy='149' rx='24' ry='43' />{' '}
          <ellipse fill='#888888' fillOpacity='0.501961' cx='35' cy='77' rx='7' ry='94' />{' '}
          <ellipse fill='#dedede' fillOpacity='0.501961' cx='101' cy='73' rx='13' ry='19' />{' '}
          <g transform='translate(84 92) rotate(13) scale(23 15)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(199.567173 43.393204) rotate(139.492150) scale(17.227413 32.655684)'>
            <ellipse fill='#aeaeae' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='70' cy='30' rx='27' ry='27' />{' '}
          <g transform='translate(175.649319 142.871043) rotate(80.540690) scale(35.006488 17.549998)'>
            <ellipse fill='#848484' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(66.520022 121.158089) rotate(321.056292) scale(18.060097 38.614877)'>
            <ellipse fill='#adadad' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='222.180502,70.554212,271.000000,106.528722,245.980036,17.106706,238.297865,103.612505'
          />{' '}
          <polygon fill='#e8e8e8' fillOpacity='0.501961' points='0,70 52,56 -16,138' />{' '}
          <ellipse fill='#888888' fillOpacity='0.501961' cx='149' cy='62' rx='18' ry='28' />{' '}
          <polygon fill='#121212' fillOpacity='0.501961' points='59,185 60,160 33,133' />{' '}
          <polygon
            fill='#1e1e1e'
            fillOpacity='0.501961'
            points='223.134986,73.911708,224.309256,110.241550,187.336973,161.065358,235.283241,109.454317'
          />{' '}
          <polygon fill='#030303' fillOpacity='0.501961' points='181,77 189,93 140,116' />{' '}
          <g transform='translate(125 36) rotate(26) scale(23 45)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='206,10 227,40 220,76' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='26.450910,103.644521,29.984141,129.977250,30.123724,126.286554,18.166036,127.442141'
          />{' '}
          <polygon
            fill='#424242'
            fillOpacity='0.501961'
            points='207.356269,47.145152,113.637731,61.116356,169.186067,62.311211,222.366444,53.030284'
          />{' '}
          <g transform='translate(22.883374 100.313832) rotate(308.960145) scale(24.324411 5.893341)'>
            <ellipse fill='#686868' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#868686'
            fillOpacity='0.501961'
            points='101.664447,90.253223,36.514672,160.074486,118.341423,103.400880,96.931272,-13.622038'
          />{' '}
          <polygon fill='#898989' fillOpacity='0.501961' points='111,142 170,150 151,129' />{' '}
          <polygon fill='#606060' fillOpacity='0.501961' points='199,19 138,-16 50,0' />{' '}
          <rect fill='#c8c8c8' fillOpacity='0.501961' x='76' y='133' width='17' height='37' />{' '}
          <polygon
            fill='#c8c8c8'
            fillOpacity='0.501961'
            points='150.448507,162.565281,139.176622,156.185631,154.828072,165.047220,90.144802,176.372629'
          />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='47' y='3' width='22' height='81' />{' '}
          <g transform='translate(254.005622 156.175084) rotate(-5.963604) scale(11.623937 64.569084)'>
            <ellipse fill='#656565' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='16.748032,140.430132,24.231213,140.643692,28.641301,153.768997,7.317140,158.614033'
          />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='254' cy='50' rx='19' ry='34' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='118' cy='84' rx='4' ry='12' />{' '}
          <polygon fill='#cacaca' fillOpacity='0.501961' points='15,132 8,154 -16,135' />{' '}
          <polygon fill='#515151' fillOpacity='0.501961' points='235,79 233,36 223,66' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='19.000339,157.552837,35.297540,152.418346,50.121059,152.481589,26.622815,157.836845'
          />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='173,34 152,9 113,25' />{' '}
          <polygon fill='#f7f7f7' fillOpacity='0.501961' points='100,90 97,8 71,10' />{' '}
          <polygon
            fill='#090909'
            fillOpacity='0.501961'
            points='32.188165,163.011566,20.779242,166.034563,-16.000000,173.350111,37.592081,154.700471'
          />{' '}
          <polygon
            fill='#c2c2c2'
            fillOpacity='0.501961'
            points='113.577028,21.978053,122.870150,18.516022,113.329479,8.902808,103.536404,56.662599'
          />{' '}
          <polygon fill='#858585' fillOpacity='0.501961' points='86,83 66,96 85,43' />{' '}
          <polygon fill='#9c9c9c' fillOpacity='0.501961' points='85,152 105,109 53,87' />{' '}
          <g transform='translate(209.071155 90.832775) rotate(272.000090) scale(44.442490 4.774028)'>
            <ellipse fill='#515151' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(40 163) rotate(79) scale(1 18)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#212121'
            fillOpacity='0.501961'
            points='152.524299,107.597682,172.616396,97.720593,139.599881,97.679849,158.041818,149.396378'
          />{' '}
          <polygon
            fill='#525252'
            fillOpacity='0.501961'
            points='56.662168,135.152187,69.351414,168.911806,59.466091,185.000000,75.345505,139.748054'
          />{' '}
          <rect fill='#080808' fillOpacity='0.501961' x='94' y='151' width='26' height='19' />{' '}
          <g transform='translate(125.520492 169.000000) rotate(262.450171) scale(37.729339 3.907838)'>
            <ellipse fill='#7f7f7f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#a2a2a2' fillOpacity='0.501961' x='177' y='58' width='38' height='11' />{' '}
          <g transform='translate(1.874861 23.313026) rotate(15.139680) scale(33.459976 28.720161)'>
            <ellipse fill='#f0f0f0' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#858585' fillOpacity='0.501961' points='37,36 23,48 44,61' />{' '}
          <polygon fill='#333333' fillOpacity='0.501961' points='232,-6 161,19 144,-16' />{' '}
          <g transform='translate(57 92) rotate(31) scale(16 1)'>
            <rect fill='#1e1e1e' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(134 155) rotate(261) scale(19 10)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(199.524394 162.991801) rotate(268.737391) scale(15.325093 3.907519)'>
            <ellipse fill='#141414' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(124.656725 146.148444) rotate(75.162393) scale(1.000000 19.946962)'>
            <ellipse fill='#a7a7a7' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#646464' fillOpacity='0.501961' cx='105' cy='56' rx='4' ry='58' />{' '}
          <ellipse fill='#a3a3a3' fillOpacity='0.501961' cx='195' cy='29' rx='13' ry='21' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='28' y='167' width='9' height='3' />{' '}
          <polygon fill='#acacac' fillOpacity='0.501961' points='201,95 188,91 217,87' />{' '}
          <polygon
            fill='#383838'
            fillOpacity='0.501961'
            points='194.724827,132.547638,220.399234,95.510289,180.496630,95.129183,195.977343,117.204040'
          />{' '}
          <rect fill='#050505' fillOpacity='0.501961' x='155' y='152' width='9' height='14' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='111' cy='133' rx='6' ry='10' />{' '}
          <polygon fill='#b5b5b5' fillOpacity='0.501961' points='71,102 30,127 42,87' />{' '}
          <polygon
            fill='#555555'
            fillOpacity='0.501961'
            points='226.587918,37.939142,200.009449,-16.000000,222.516464,-16.000000,266.781684,0.976705'
          />{' '}
          <g transform='translate(39 168) rotate(245) scale(61 1)'>
            <rect fill='#fdfdfd' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#878787' fillOpacity='0.501961' points='151,131 134,110 149,177' />{' '}
          <g transform='translate(18.870507 169.000000) rotate(340.740931) scale(18.272716 1.000000)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='31' y='64' width='37' height='18' />{' '}
          <polygon fill='#fefefe' fillOpacity='0.501961' points='220,23 201,9 226,57' />{' '}
          <g transform='translate(0.876654 128.023148) rotate(236.623779) scale(5.970759 17.223551)'>
            <ellipse fill='#4d4d4d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(152 45) rotate(63) scale(10 31)'>
            <rect fill='#949494' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(255.000000 169.000000) rotate(147.597968) scale(44.829310 14.273926)'>
            <ellipse fill='#494949' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#7b7b7b'
            fillOpacity='0.501961'
            points='127.182900,117.951007,134.224473,94.743965,121.752837,121.191801,98.435721,108.785620'
          />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='42' y='7' width='45' height='44' />{' '}
          <g transform='translate(30 143) rotate(233) scale(20 3)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#ababab' fillOpacity='0.501961' cx='90' cy='4' rx='3' ry='45' />{' '}
          <polygon
            fill='#e6e6e6'
            fillOpacity='0.501961'
            points='83.092898,82.410112,104.202783,83.759384,94.453013,92.281435,97.288590,59.000745'
          />{' '}
          <rect fill='#dedede' fillOpacity='0.501961' x='111' y='71' width='3' height='25' />{' '}
          <polygon
            fill='#cecece'
            fillOpacity='0.501961'
            points='62.683475,41.886670,22.179395,95.808026,-16.000000,146.621210,6.183790,82.307474'
          />{' '}
          <polygon
            fill='#343434'
            fillOpacity='0.501961'
            points='72.519039,83.849240,63.327334,117.807802,68.087761,100.197854,97.370772,98.606364'
          />{' '}
          <g transform='translate(134 140) rotate(77) scale(5 10)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(158.564940 169.000000) rotate(252.223398) scale(1.000000 20.710131)'>
            <ellipse fill='#cccccc' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='37' cy='83' rx='5' ry='2' />{' '}
          <rect fill='#898989' fillOpacity='0.501961' x='149' y='82' width='16' height='16' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='199,58 203,68 203,59' />{' '}
          <ellipse fill='#e6e6e6' fillOpacity='0.501961' cx='78' cy='57' rx='4' ry='14' />{' '}
          <g transform='translate(131.594295 99.477275) rotate(297.776345) scale(5.950857 9.919375)'>
            <ellipse fill='#818181' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#a7a7a7'
            fillOpacity='0.501961'
            points='3.575020,131.517783,19.225281,128.378666,102.112784,137.607135,46.779404,137.594164'
          />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='123,76 120,90 113,68' />{' '}
          <polygon
            fill='#797979'
            fillOpacity='0.501961'
            points='163.652337,7.125481,119.374326,15.023574,71.850708,4.292047,152.795100,5.385952'
          />{' '}
          <polygon
            fill='#999999'
            fillOpacity='0.501961'
            points='122.172505,127.133221,124.099061,122.406130,127.572726,162.399602,126.523999,157.702802'
          />{' '}
          <g transform='translate(57.305783 87.513134) rotate(231.548756) scale(3.175571 4.957375)'>
            <ellipse fill='#5d5d5d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#c2c2c2'
            fillOpacity='0.501961'
            points='108.557225,38.635311,108.606243,8.955658,115.336199,14.040370,119.843746,9.987481'
          />{' '}
        </g>
      </g>
    </svg>
  );
};

export const Atelier5Logo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 886 591'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#9f9f9f' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(3.460938) translate(0.5 0.5)'>
          {' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='54' cy='118' rx='42' ry='42' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='164.977733,-0.769525,221.547983,64.306302,271.000000,84.443607,179.806250,-7.286133'
          />{' '}
          <polygon fill='#e3e3e3' fillOpacity='0.501961' points='229,69 90,-16 97,185' />{' '}
          <g transform='translate(60.797672 97.965289) rotate(211.081266) scale(39.848698 17.653459)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#767676' fillOpacity='0.501961' cx='46' cy='149' rx='37' ry='43' />{' '}
          <ellipse fill='#cecece' fillOpacity='0.501961' cx='46' cy='13' rx='129' ry='31' />{' '}
          <g transform='translate(77.160487 144.416393) rotate(281.350219) scale(41.477047 13.559310)'>
            <ellipse fill='#141414' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#e4e4e4'
            fillOpacity='0.501961'
            points='0.262728,185.000000,-1.844657,-3.174607,23.572752,95.061018,5.317370,141.888762'
          />{' '}
          <polygon fill='#5c5c5c' fillOpacity='0.501961' points='115,59 116,37 40,51' />{' '}
          <ellipse fill='#b5b5b5' fillOpacity='0.501961' cx='39' cy='124' rx='21' ry='14' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='167' cy='61' rx='6' ry='54' />{' '}
          <polygon
            fill='#c8c8c8'
            fillOpacity='0.501961'
            points='189.527590,-6.100687,271.000000,85.645357,239.036490,87.953886,271.000000,3.973526'
          />{' '}
          <rect fill='#393939' fillOpacity='0.501961' x='39' y='18' width='48' height='7' />{' '}
          <polygon
            fill='#d8d8d8'
            fillOpacity='0.501961'
            points='113.724018,82.545503,37.359790,75.271356,4.459507,57.343667,96.206164,64.841871'
          />{' '}
          <g transform='translate(173.354176 0.000000) rotate(50.681621) scale(91.636252 5.577170)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(56 94) rotate(283) scale(27 59)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#131313' fillOpacity='0.501961' cx='241' cy='92' rx='26' ry='3' />{' '}
          <polygon fill='#c1c1c1' fillOpacity='0.501961' points='117,174 74,52 241,113' />{' '}
          <g transform='translate(38.741656 52.074624) rotate(188.487014) scale(28.202308 10.048687)'>
            <ellipse fill='#6c6c6c' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#747474' fillOpacity='0.501961' cx='43' cy='144' rx='36' ry='36' />{' '}
          <g transform='translate(95.114529 53.221883) rotate(201.142505) scale(6.162402 17.044781)'>
            <ellipse fill='#dcdcdc' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(207.773927 21.633443) rotate(140.411215) scale(6.513112 46.046294)'>
            <ellipse fill='#868686' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(191 47) rotate(319) scale(23 61)'>
            <rect fill='#cbcbcb' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='56' y='41' width='3' height='17' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='156,112 171,80 188,85' />{' '}
          <polygon fill='#040404' fillOpacity='0.501961' points='222,67 240,68 200,26' />{' '}
          <g transform='translate(47.092181 94.955668) rotate(158.312849) scale(31.017168 7.491766)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='19,40 7,63 16,55' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='82' y='102' width='9' height='49' />{' '}
          <g transform='translate(234 129) rotate(221) scale(50 22)'>
            <rect fill='#c0c0c0' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#dadada' fillOpacity='0.501961' cx='54' cy='61' rx='55' ry='2' />{' '}
          <polygon fill='#d4d4d4' fillOpacity='0.501961' points='39,-16 30,61 8,-16' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='85' y='45' width='3' height='15' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='167' cy='43' rx='4' ry='39' />{' '}
          <ellipse fill='#b7b7b7' fillOpacity='0.501961' cx='225' cy='75' rx='37' ry='7' />{' '}
          <polygon fill='#cacaca' fillOpacity='0.501961' points='33,2 -2,88 -14,-11' />{' '}
          <g transform='translate(26 52) rotate(155) scale(1 27)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#4a4a4a' fillOpacity='0.501961' cx='63' cy='164' rx='22' ry='29' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='71' y='58' width='11' height='2' />{' '}
          <g transform='translate(33.292155 46.889479) rotate(180.042244) scale(1.716916 6.770771)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#202020' fillOpacity='0.501961' x='104' y='39' width='7' height='11' />{' '}
          <g transform='translate(156.241779 135.822472) rotate(11.124447) scale(1.000000 45.882733)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#dedede' fillOpacity='0.501961' points='84,54 89,26 78,32' />{' '}
          <polygon
            fill='#9b9b9b'
            fillOpacity='0.501961'
            points='39.322314,136.666335,31.284088,185.000000,54.739117,114.153187,20.966019,110.591644'
          />{' '}
          <g transform='translate(129.138747 19.145508) rotate(272.755306) scale(23.249649 36.115079)'>
            <ellipse fill='#b8b8b8' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(60 60) rotate(96) scale(30 4)'>
            <rect fill='#dbdbdb' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#cbcbcb' fillOpacity='0.501961' cx='26' cy='65' rx='131' ry='6' />{' '}
          <polygon
            fill='#656565'
            fillOpacity='0.501961'
            points='136.676119,185.000000,143.077891,155.230047,171.469501,161.698413,148.980874,152.486682'
          />{' '}
          <polygon
            fill='#c6c6c6'
            fillOpacity='0.501961'
            points='102.787854,102.729882,145.162510,160.790100,97.240839,43.707281,78.244976,87.195548'
          />{' '}
          <polygon
            fill='#d3d3d3'
            fillOpacity='0.501961'
            points='34.424149,33.994458,38.597396,50.357377,55.521380,28.375291,32.061021,20.672629'
          />{' '}
          <polygon fill='#5f5f5f' fillOpacity='0.501961' points='210,88 248,93 220,98' />{' '}
          <polygon
            fill='#202020'
            fillOpacity='0.501961'
            points='113.658923,53.976159,112.857705,41.880992,117.167154,56.503859,108.620680,60.411713'
          />{' '}
          <g transform='translate(54.179346 55.004305) rotate(92.739475) scale(15.690099 1.329234)'>
            <ellipse fill='#e5e5e5' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#848484'
            fillOpacity='0.501961'
            points='169.717284,91.713746,205.387295,101.477922,119.340902,82.098623,101.365915,74.820101'
          />{' '}
          <polygon fill='#080808' fillOpacity='0.501961' points='66,46 63,55 63,35' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='107' y='49' width='5' height='9' />{' '}
          <polygon
            fill='#040404'
            fillOpacity='0.501961'
            points='31.067174,73.809707,23.878275,73.954843,37.381101,102.282413,92.743956,101.616495'
          />{' '}
          <g transform='translate(107 58) rotate(121) scale(2 9)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='33' y='44' width='2' height='15' />{' '}
          <polygon
            fill='#cecece'
            fillOpacity='0.501961'
            points='68.657964,43.862941,68.497268,58.310697,56.470868,25.140245,100.316714,45.059255'
          />{' '}
          <ellipse fill='#3f3f3f' fillOpacity='0.501961' cx='46' cy='52' rx='8' ry='8' />{' '}
          <rect fill='#cfcfcf' fillOpacity='0.501961' x='15' y='55' width='9' height='38' />{' '}
          <g transform='translate(255 94) rotate(100) scale(1 36)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#d8d8d8' fillOpacity='0.501961' x='106' y='43' width='20' height='4' />{' '}
          <polygon fill='#2a2a2a' fillOpacity='0.501961' points='87,24 91,20 87,16' />{' '}
          <polygon fill='#b9b9b9' fillOpacity='0.501961' points='239,83 173,69 172,9' />{' '}
          <g transform='translate(70 51) rotate(369) scale(1 11)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(89 47) rotate(157) scale(9 1)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#282828' fillOpacity='0.501961' x='38' y='12' width='2' height='15' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='157,139 151,150 155,153' />{' '}
          <rect fill='#d1d1d1' fillOpacity='0.501961' x='44' y='47' width='6' height='10' />{' '}
          <polygon
            fill='#cecece'
            fillOpacity='0.501961'
            points='-16.000000,170.826528,3.687177,56.700591,9.829875,165.020371,19.971542,185.000000'
          />{' '}
          <g transform='translate(191.365058 25.183404) rotate(139.332233) scale(1.948769 55.866184)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#7d7d7d' fillOpacity='0.501961' points='269,141 250,110 227,107' />{' '}
          <g transform='translate(91.294027 139.649587) rotate(368.695575) scale(2.594385 34.242467)'>
            <ellipse fill='#6a6a6a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#1b1b1b' fillOpacity='0.501961' x='224' y='62' width='27' height='8' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='18' y='39' width='4' height='5' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='41' y='51' width='16' height='2' />{' '}
          <rect fill='#dfdfdf' fillOpacity='0.501961' x='53' y='28' width='3' height='35' />{' '}
          <ellipse fill='#c9c9c9' fillOpacity='0.501961' cx='57' cy='3' rx='34' ry='14' />{' '}
          <polygon fill='#575757' fillOpacity='0.501961' points='10,128 16,151 20,100' />{' '}
          <g transform='translate(204.091596 153.452573) rotate(100.144414) scale(26.213760 46.459226)'>
            <ellipse fill='#9b9b9b' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='192' cy='86' rx='14' ry='2' />{' '}
          <g transform='translate(193 5) rotate(233) scale(46 9)'>
            <rect fill='#676767' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#787878'
            fillOpacity='0.501961'
            points='48.628012,133.152904,55.836171,140.162477,50.722907,162.954015,75.114452,165.008989'
          />{' '}
          <rect fill='#f6f6f6' fillOpacity='0.501961' x='36' y='50' width='3' height='9' />{' '}
          <polygon
            fill='#b5b5b5'
            fillOpacity='0.501961'
            points='252.590267,67.408890,237.328318,52.589116,256.844271,-16.000000,260.179526,136.967007'
          />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='173.614417,83.654075,166.787776,19.123966,163.006982,50.300516,159.902841,80.239067'
          />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='171,94 161,92 159,116' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='101,48 109,45 113,47' />{' '}
          <g transform='translate(194.422396 111.738795) rotate(241.050970) scale(22.438529 28.703152)'>
            <ellipse fill='#aeaeae' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(207.571610 38.221368) rotate(229.169231) scale(22.047987 1.000000)'>
            <ellipse fill='#494949' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#cacaca'
            fillOpacity='0.501961'
            points='105.677686,44.590766,23.344996,34.297046,33.716749,73.039311,29.541363,22.896095'
          />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='69' y='51' width='14' height='2' />{' '}
          <ellipse fill='#111111' fillOpacity='0.501961' cx='64' cy='40' rx='1' ry='22' />{' '}
          <polygon fill='#c9c9c9' fillOpacity='0.501961' points='82,23 98,45 42,33' />{' '}
          <g transform='translate(22 53) rotate(285) scale(1 7)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#070707' fillOpacity='0.501961' x='79' y='47' width='3' height='11' />{' '}
          <polygon
            fill='#797979'
            fillOpacity='0.501961'
            points='25.540501,70.612797,46.343642,71.500124,84.741768,81.251120,55.161814,80.008394'
          />{' '}
          <g transform='translate(243.145410 100.723693) rotate(41.374523) scale(98.016685 1.163454)'>
            <ellipse fill='#afafaf' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
        </g>
      </g>
    </svg>
  );
};

export const ApoVBBanner = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 886 639'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#758463' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(3.460938) translate(0.5 0.5)'>
          {' '}
          <g transform='translate(33.112643 116.112484) rotate(283.768103) scale(26.850319 57.448072)'>
            <ellipse fill='#d8c9f7' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(81.228207 67.557230) rotate(96.301652) scale(28.624888 59.834731)'>
            <ellipse fill='#32271b' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#2e5d03' fillOpacity='0.501961' cx='181' cy='18' rx='32' ry='64' />{' '}
          <ellipse fill='#d3cdd8' fillOpacity='0.501961' cx='238' cy='178' rx='58' ry='31' />{' '}
          <g transform='translate(119.919125 166.830291) rotate(87.275713) scale(32.325632 62.339442)'>
            <ellipse fill='#304c17' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(240.683328 17.273181) rotate(25.871880) scale(20.675828 45.795826)'>
            <ellipse fill='#d5c8d6' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#89bb41' fillOpacity='0.501961' cx='200' cy='107' rx='56' ry='56' />{' '}
          <polygon
            fill='#00005f'
            fillOpacity='0.501961'
            points='48.339223,146.647222,16.180141,141.344823,-0.271461,140.120665,52.304907,197.307357'
          />{' '}
          <rect fill='#fff5ff' fillOpacity='0.501961' x='0' y='167' width='37' height='17' />{' '}
          <polygon
            fill='#01003b'
            fillOpacity='0.501961'
            points='53.489257,119.620865,36.626137,84.833334,58.499092,84.945205,52.976310,108.188501'
          />{' '}
          <ellipse fill='#ff8a5f' fillOpacity='0.501961' cx='53' cy='70' rx='13' ry='12' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='52,33 79,50 73,81' />{' '}
          <g transform='translate(138.558671 70.704957) rotate(82.583707) scale(21.819276 48.974878)'>
            <ellipse fill='#3d721b' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(110 23) rotate(342) scale(82 50)'>
            <rect fill='#887e93' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(26.456548 105.014675) rotate(282.588454) scale(24.870406 14.677897)'>
            <ellipse fill='#dedbe2' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(12.617090 23.114292) rotate(20.793679) scale(35.105582 111.922021)'>
            <ellipse fill='#737d91' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#659324' fillOpacity='0.501961' points='91,138 110,97 218,160' />{' '}
          <polygon
            fill='#9f8fb5'
            fillOpacity='0.501961'
            points='111.241281,170.643851,118.443616,167.554836,166.892960,199.000000,94.484431,183.187739'
          />{' '}
          <ellipse fill='#726e82' fillOpacity='0.501961' cx='80' cy='129' rx='7' ry='84' />{' '}
          <polygon
            fill='#d0c9e0'
            fillOpacity='0.501961'
            points='-9.809048,133.532463,42.507931,124.929485,-16.000000,154.136939,44.979771,199.000000'
          />{' '}
          <g transform='translate(200.240579 119.274866) rotate(284.045047) scale(6.190909 82.467667)'>
            <ellipse fill='#b1bf9d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='42,40 36,64 62,39' />{' '}
          <rect fill='#10004e' fillOpacity='0.501961' x='13' y='147' width='36' height='18' />{' '}
          <g transform='translate(101 73) rotate(103) scale(35 20)'>
            <rect fill='#0d2a08' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(67 77) rotate(210) scale(7 31)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#edddff' fillOpacity='0.501961' x='52' y='174' width='9' height='10' />{' '}
          <polygon
            fill='#8f8c91'
            fillOpacity='0.501961'
            points='141.587191,53.647632,53.165601,25.343794,91.373908,61.749389,164.268571,-16.000000'
          />{' '}
          <g transform='translate(243.859695 73.980129) rotate(81.072580) scale(25.844795 45.237543)'>
            <ellipse fill='#84a548' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#375d1d' fillOpacity='0.501961' points='173,194 83,152 187,141' />{' '}
          <rect fill='#0b0044' fillOpacity='0.501961' x='47' y='93' width='5' height='32' />{' '}
          <polygon fill='#31493e' fillOpacity='0.501961' points='-16,103 10,81 -5,141' />{' '}
          <polygon
            fill='#c0bdc5'
            fillOpacity='0.501961'
            points='219.532479,168.613800,256.804266,137.089566,271.000000,162.166348,271.000000,189.569073'
          />{' '}
          <g transform='translate(61.498236 118.858766) rotate(182.067934) scale(10.470022 24.098854)'>
            <ellipse fill='#cdcbd0' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#908c9b'
            fillOpacity='0.501961'
            points='87.849325,72.228928,83.397038,156.469523,103.372278,105.424981,59.793933,106.969547'
          />{' '}
          <g transform='translate(41.757703 85.665789) rotate(252.896644) scale(25.690682 3.013857)'>
            <ellipse fill='#0c003d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(94.887789 155.328921) rotate(285.220525) scale(35.922812 11.258660)'>
            <ellipse fill='#40541d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(163.859823 26.578032) rotate(115.632994) scale(29.623041 12.124212)'>
            <ellipse fill='#38531d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(54 70) rotate(116) scale(32 17)'>
            <rect fill='#c67050' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(55.950119 157.487640) rotate(215.151211) scale(7.100784 13.475180)'>
            <ellipse fill='#c4634c' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000031' fillOpacity='0.501961' points='51,191 45,160 34,184' />{' '}
          <polygon
            fill='#afaaaf'
            fillOpacity='0.501961'
            points='200.888537,-16.000000,271.000000,32.057519,252.998000,44.021486,233.197079,45.748701'
          />{' '}
          <polygon
            fill='#ccc9cc'
            fillOpacity='0.501961'
            points='-0.455387,140.999398,17.700411,140.235858,80.857062,147.892623,25.037085,118.687173'
          />{' '}
          <g transform='translate(160.565515 73.234884) rotate(428.461881) scale(18.868291 46.949957)'>
            <ellipse fill='#618a3a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#62457d' fillOpacity='0.501961' cx='68' cy='175' rx='8' ry='27' />{' '}
          <polygon
            fill='#cac9cf'
            fillOpacity='0.501961'
            points='40.103049,87.784052,15.201172,101.844021,36.011276,74.345635,53.045915,146.001937'
          />{' '}
          <g transform='translate(81.041430 12.464320) rotate(-27.033625) scale(39.914504 21.082169)'>
            <ellipse fill='#706d73' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(16.713517 150.670894) rotate(168.295376) scale(9.532246 10.793253)'>
            <ellipse fill='#170253' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='48.070892,38.891743,32.712808,56.961978,67.982867,45.950103,72.490528,40.444569'
          />{' '}
          <g transform='translate(100 99) rotate(33) scale(18 15)'>
            <rect fill='#546d48' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#283a02' fillOpacity='0.501961' points='48,122 43,126 38,119' />{' '}
          <g transform='translate(143.453805 126.154299) rotate(25.072814) scale(32.461465 17.856350)'>
            <ellipse fill='#7ca33e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#b5b5b5'
            fillOpacity='0.501961'
            points='243.240543,47.133886,271.000000,36.247582,214.446537,36.001087,209.469153,56.211000'
          />{' '}
          <polygon
            fill='#234115'
            fillOpacity='0.501961'
            points='99.560057,49.649777,113.772176,47.276433,114.879172,70.070283,89.289472,93.735019'
          />{' '}
          <polygon fill='#8fb73f' fillOpacity='0.501961' points='196,112 127,96 204,78' />{' '}
          <rect fill='#1c0440' fillOpacity='0.501961' x='66' y='149' width='10' height='12' />{' '}
          <g transform='translate(38.883069 70.257964) rotate(83.749040) scale(10.661753 2.706730)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#739445' fillOpacity='0.501961' points='203,60 242,47 196,127' />{' '}
          <g transform='translate(58 28) rotate(105) scale(61 1)'>
            <rect fill='#1d0f15' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(71 142) rotate(62) scale(11 10)'>
            <rect fill='#d9d7e3' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#9db260' fillOpacity='0.501961' points='216,62 267,72 254,41' />{' '}
          <polygon fill='#bdbbce' fillOpacity='0.501961' points='39,171 -16,154 8,199' />{' '}
          <g transform='translate(33.228789 22.637147) rotate(276.599245) scale(22.260179 24.124313)'>
            <ellipse fill='#818985' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#aa9fba'
            fillOpacity='0.501961'
            points='56.913877,168.758650,44.508938,166.065207,52.275967,173.682484,47.274275,199.000000'
          />{' '}
          <ellipse fill='#93a95b' fillOpacity='0.501961' cx='215' cy='148' rx='28' ry='8' />{' '}
          <polygon fill='#676c72' fillOpacity='0.501961' points='85,91 63,96 101,32' />{' '}
          <polygon
            fill='#274a11'
            fillOpacity='0.501961'
            points='86.352205,93.328833,95.703504,105.645076,110.933566,108.910047,108.095659,106.101814'
          />{' '}
          <g transform='translate(110 97) rotate(289) scale(7 36)'>
            <rect fill='#89977e' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#446127' fillOpacity='0.501961' cx='184' cy='20' rx='33' ry='24' />{' '}
          <polygon fill='#a76e46' fillOpacity='0.501961' points='45,165 64,141 68,160' />{' '}
          <polygon
            fill='#080003'
            fillOpacity='0.501961'
            points='51.052067,38.344518,58.773620,37.629049,77.336107,80.454741,73.050322,42.676302'
          />{' '}
          <rect fill='#af5c3a' fillOpacity='0.501961' x='63' y='53' width='5' height='23' />{' '}
          <polygon
            fill='#939193'
            fillOpacity='0.501961'
            points='163.593447,-16.000000,122.322425,46.622001,145.335158,52.684378,147.487800,35.368489'
          />{' '}
          <rect fill='#a6a3ac' fillOpacity='0.501961' x='183' y='160' width='73' height='24' />{' '}
          <g transform='translate(66 18) rotate(286) scale(32 6)'>
            <rect fill='#a79c99' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#98959e'
            fillOpacity='0.501961'
            points='78.422789,95.405020,90.481819,133.630821,108.726569,103.522373,101.225524,109.605877'
          />{' '}
          <polygon fill='#537532' fillOpacity='0.501961' points='216,8 206,67 121,64' />{' '}
          <polygon fill='#000001' fillOpacity='0.501961' points='76,77 54,88 71,69' />{' '}
          <polygon
            fill='#56696d'
            fillOpacity='0.501961'
            points='13.344300,87.723507,37.687070,77.659004,23.315410,60.671552,-12.936849,120.563128'
          />{' '}
          <polygon
            fill='#c9c9c9'
            fillOpacity='0.501961'
            points='44.160899,100.986260,50.349966,127.025221,9.562560,90.806872,32.312121,80.434409'
          />{' '}
          <polygon
            fill='#231358'
            fillOpacity='0.501961'
            points='20.845066,168.159361,4.616300,139.617701,42.592785,145.987405,40.100244,164.972889'
          />{' '}
          <polygon fill='#7daf43' fillOpacity='0.501961' points='254,113 271,123 242,148' />{' '}
          <polygon fill='#a5a2ab' fillOpacity='0.501961' points='11,172 2,118 -16,136' />{' '}
          <g transform='translate(65 98) rotate(33) scale(21 1)'>
            <rect fill='#ccc3dd' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#70727c' fillOpacity='0.501961' points='75,198 62,183 87,131' />{' '}
          <polygon
            fill='#a09b9a'
            fillOpacity='0.501961'
            points='213.889321,7.519771,193.488800,-9.334599,262.269584,-16.000000,229.712615,34.040765'
          />{' '}
          <polygon
            fill='#828187'
            fillOpacity='0.501961'
            points='185.719089,199.000000,87.062568,199.000000,121.366808,165.370670,124.647973,173.818244'
          />{' '}
          <polygon fill='#13004b' fillOpacity='0.501961' points='48,114 57,88 43,89' />{' '}
          <g transform='translate(178 114) rotate(105) scale(7 39)'>
            <rect fill='#b3bf9e' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#7e7f80' fillOpacity='0.501961' x='75' y='93' width='13' height='55' />{' '}
          <polygon
            fill='#577f35'
            fillOpacity='0.501961'
            points='195.861324,107.255795,179.105998,175.211321,157.239882,152.728921,186.159167,129.159492'
          />{' '}
          <polygon fill='#abb4a2' fillOpacity='0.501961' points='237,123 250,137 206,125' />{' '}
          <polygon
            fill='#385622'
            fillOpacity='0.501961'
            points='132.718416,49.629457,98.897947,95.287929,103.416525,53.364204,101.093268,46.299521'
          />{' '}
          <rect fill='#607d35' fillOpacity='0.501961' x='103' y='101' width='17' height='64' />{' '}
          <polygon
            fill='#858481'
            fillOpacity='0.501961'
            points='137.093634,32.650475,101.139184,49.728879,150.608538,-16.000000,171.250996,-9.058327'
          />{' '}
          <rect fill='#d2d5d4' fillOpacity='0.501961' x='8' y='124' width='21' height='16' />{' '}
          <polygon
            fill='#bdbdba'
            fillOpacity='0.501961'
            points='263.913353,149.281714,208.278243,160.945058,217.550992,169.066714,258.070182,136.302881'
          />{' '}
          <g transform='translate(34 171) rotate(249) scale(3 10)'>
            <rect fill='#cfced3' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#bb7450' fillOpacity='0.501961' x='44' y='54' width='15' height='14' />{' '}
          <g transform='translate(205 153) rotate(354) scale(36 15)'>
            <rect fill='#849b56' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#959599'
            fillOpacity='0.501961'
            points='235.210758,197.166902,175.700037,184.418865,167.637127,194.198324,188.102300,164.070298'
          />{' '}
        </g>
      </g>
    </svg>
  );
};

export const ApoVBLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 300 200'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#222b0f' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(1.171875) translate(0.5 0.5)'>
          {' '}
          <g transform='translate(128.473031 70.015167) rotate(-126.873018) scale(50.224258 68.415631)'>
            <ellipse fill='#cdff59' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='142.687927,43.340030,144.574976,-16.000000,216.600586,5.221468,137.297613,50.293261'
          />{' '}
          <g transform='translate(207.154699 128.871819) rotate(148.088635) scale(139.117081 46.873209)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='35' cy='79' rx='41' ry='250' />{' '}
          <g transform='translate(91.693613 110.172571) rotate(18.797409) scale(12.741215 42.081931)'>
            <ellipse fill='#cffa59' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(226.798259 90.555187) rotate(98.273680) scale(186.612495 42.134559)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#b3dc69' fillOpacity='0.501961' points='62,50 185,86 66,86' />{' '}
          <polygon fill='#e9ff7f' fillOpacity='0.501961' points='190,52 191,10 163,47' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='0' width='102' height='48' />{' '}
          <g transform='translate(168.391744 0.000000) rotate(113.208870) scale(40.697218 19.961087)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#9cc93a' fillOpacity='0.501961' points='144,33 85,127 142,128' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='27' cy='117' rx='40' ry='147' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='270,58 271,185 20,185' />{' '}
          <polygon
            fill='#80b02a'
            fillOpacity='0.501961'
            points='102.894756,77.504598,162.065614,77.164838,134.897861,10.559395,102.620518,11.882391'
          />{' '}
          <g transform='translate(230 41) rotate(277) scale(132 75)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='0' width='101' height='49' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='49,185 -16,82 82,91' />{' '}
          <rect fill='#dde5c5' fillOpacity='0.501961' x='89' y='79' width='86' height='6' />{' '}
          <rect fill='#6f9f1d' fillOpacity='0.501961' x='66' y='50' width='46' height='30' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='250,184 261,56 142,112' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='111' cy='154' rx='29' ry='23' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='145,-16 146,42 180,23' />{' '}
          <polygon
            fill='#bcdd60'
            fillOpacity='0.501961'
            points='78.682270,119.014341,98.272748,124.867129,77.227994,161.075913,74.682642,128.442429'
          />{' '}
          <polygon
            fill='#a3c853'
            fillOpacity='0.501961'
            points='161.729888,38.074634,140.188903,46.400036,189.065814,62.587231,190.698233,15.724679'
          />{' '}
          <polygon
            fill='#bcde60'
            fillOpacity='0.501961'
            points='143.436199,109.084037,179.449774,93.085651,128.619865,89.420789,144.248693,87.374592'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='121' cy='0' rx='163' ry='11' />{' '}
          <rect fill='#92bc47' fillOpacity='0.501961' x='64' y='53' width='11' height='32' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='-16,61 19,174 91,-13' />{' '}
          <ellipse fill='#cfeaa6' fillOpacity='0.501961' cx='73' cy='84' rx='6' ry='6' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='91' width='75' height='79' />{' '}
          <ellipse fill='#7fab33' fillOpacity='0.501961' cx='120' cy='24' rx='19' ry='14' />{' '}
          <rect fill='#86ae2c' fillOpacity='0.501961' x='82' y='89' width='59' height='39' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='218.368177,-16.000000,87.447650,18.229287,115.392358,-16.000000,158.828659,36.839962'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='224' cy='7' rx='33' ry='255' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='71,169 105,124 225,185' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='144' y='108' width='112' height='62' />{' '}
          <rect fill='#7ca52c' fillOpacity='0.501961' x='66' y='50' width='107' height='30' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='-2.291504,165.673691,66.885016,53.955649,128.391537,-7.235615,-0.320023,-16.000000'
          />{' '}
          <rect fill='#ccdca4' fillOpacity='0.501961' x='83' y='80' width='82' height='6' />{' '}
          <polygon fill='#6e992f' fillOpacity='0.501961' points='144,121 145,94 96,124' />{' '}
          <polygon
            fill='#9abe38'
            fillOpacity='0.501961'
            points='74.285890,119.881306,85.350118,141.527022,146.754872,87.885105,83.348476,88.643869'
          />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='99,46 -10,50 105,-16' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='-16.000000,117.805171,63.397609,15.364669,64.725933,125.841847,84.253130,90.314318'
          />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='181.020830,87.524755,79.913023,185.000000,271.000000,133.984495,219.297176,-16.000000'
          />{' '}
          <ellipse fill='#78a12f' fillOpacity='0.501961' cx='124' cy='27' rx='21' ry='18' />{' '}
          <polygon
            fill='#a8c55b'
            fillOpacity='0.501961'
            points='110.727679,119.263979,86.446555,141.777752,79.198255,160.145786,77.052327,128.161630'
          />{' '}
          <polygon fill='#afce5a' fillOpacity='0.501961' points='175,36 192,8 186,70' />{' '}
          <polygon
            fill='#a7c83c'
            fillOpacity='0.501961'
            points='146.080562,74.221535,148.657848,54.211908,116.522801,75.077650,114.509253,87.635987'
          />{' '}
          <polygon fill='#e0e7da' fillOpacity='0.501961' points='71,80 79,85 86,81' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='148.373124,28.521830,144.086451,44.803305,180.947282,26.254148,129.533283,-16.000000'
          />{' '}
          <rect fill='#749c2f' fillOpacity='0.501961' x='109' y='108' width='31' height='22' />{' '}
          <rect fill='#77a12f' fillOpacity='0.501961' x='70' y='49' width='111' height='14' />{' '}
          <polygon
            fill='#749f2c'
            fillOpacity='0.501961'
            points='139.839189,91.433818,158.148444,68.150014,152.723755,75.067439,140.555586,108.110548'
          />{' '}
          <polygon fill='#b2cf5e' fillOpacity='0.501961' points='159,104 147,90 175,92' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='111' cy='155' rx='27' ry='27' />{' '}
          <rect fill='#c5d8ad' fillOpacity='0.501961' x='149' y='80' width='27' height='6' />{' '}
          <polygon
            fill='#a9c657'
            fillOpacity='0.501961'
            points='188.124292,34.769265,192.814103,12.187489,155.820821,50.724778,140.184033,45.298661'
          />{' '}
          <ellipse fill='#82ae28' fillOpacity='0.501961' cx='87' cy='89' rx='17' ry='3' />{' '}
          <g transform='translate(172.046127 11.295080) rotate(240.540188) scale(13.811251 20.442905)'>
            <ellipse fill='#000001' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='83,185 -16,94 74,139' />{' '}
          <rect fill='#78a02e' fillOpacity='0.501961' x='105' y='18' width='40' height='34' />{' '}
          <g transform='translate(118 83) rotate(247) scale(24 3)'>
            <rect fill='#94bc22' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='146,110 249,68 139,185' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='69,142 62,81 78,101' />{' '}
          <rect fill='#9bc03a' fillOpacity='0.501961' x='78' y='103' width='3' height='58' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='167.066133,34.654651,212.427893,-16.000000,60.572316,8.099064,136.761306,11.154029'
          />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='165.361567,117.341095,126.026761,132.665552,64.191486,168.850478,229.034101,185.000000'
          />{' '}
          <ellipse fill='#a5c454' fillOpacity='0.501961' cx='184' cy='52' rx='4' ry='24' />{' '}
          <ellipse fill='#79a22e' fillOpacity='0.501961' cx='124' cy='117' rx='21' ry='12' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='49' y='0' width='52' height='47' />{' '}
          <ellipse fill='#e7ecdb' fillOpacity='0.501961' cx='72' cy='82' rx='3' ry='3' />{' '}
          <polygon fill='#719b27' fillOpacity='0.501961' points='66,87 80,58 64,67' />{' '}
          <polygon fill='#98be2f' fillOpacity='0.501961' points='112,109 142,85 86,85' />{' '}
          <polygon
            fill='#a6c356'
            fillOpacity='0.501961'
            points='190.062148,58.310176,188.510221,18.592253,155.280683,43.941051,173.072214,48.688123'
          />{' '}
          <polygon fill='#e3edca' fillOpacity='0.501961' points='124,76 123,83 119,79' />{' '}
          <g transform='translate(165.447609 97.492414) rotate(331.498962) scale(13.628521 3.339761)'>
            <ellipse fill='#9db754' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#adc95e'
            fillOpacity='0.501961'
            points='95.186567,116.491805,105.111113,122.112749,83.149072,143.441897,87.089808,124.273512'
          />{' '}
          <rect fill='#77a02c' fillOpacity='0.501961' x='74' y='48' width='2' height='36' />{' '}
          <g transform='translate(92 89) rotate(265) scale(19 1)'>
            <rect fill='#8ab12d' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#79a030'
            fillOpacity='0.501961'
            points='102.770529,19.586489,110.959040,10.128372,137.024715,10.681081,122.971802,81.311403'
          />{' '}
          <rect fill='#77a32d' fillOpacity='0.501961' x='146' y='52' width='36' height='28' />{' '}
          <polygon
            fill='#779d2f'
            fillOpacity='0.501961'
            points='78.701548,48.669601,74.716431,77.050739,101.770023,79.339147,139.344033,51.042981'
          />{' '}
          <polygon fill='#9cc133' fillOpacity='0.501961' points='151,52 129,76 101,78' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='239' cy='0' rx='48' ry='182' />{' '}
          <polygon
            fill='#79a131'
            fillOpacity='0.501961'
            points='151.784433,74.737333,145.512112,93.202331,153.282952,55.630011,155.813625,90.325187'
          />{' '}
          <g transform='translate(99 140) rotate(313) scale(31 10)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#6fa121' fillOpacity='0.501961' x='143' y='86' width='37' height='3' />{' '}
          <ellipse fill='#e7f5d4' fillOpacity='0.501961' cx='175' cy='80' rx='2' ry='2' />{' '}
          <polygon
            fill='#010002'
            fillOpacity='0.501961'
            points='160.473878,37.857761,152.547963,41.798661,133.381885,-15.926430,184.058697,21.045166'
          />{' '}
          <polygon fill='#aecc59' fillOpacity='0.501961' points='175,90 145,108 145,92' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='69.220672,138.731519,80.242731,92.453337,75.082099,93.316425,-16.000000,26.537735'
          />{' '}
          <polygon fill='#77a02d' fillOpacity='0.501961' points='170,48 153,48 161,82' />{' '}
          <polygon
            fill='#95bc2e'
            fillOpacity='0.501961'
            points='77.603425,138.065123,75.403627,121.170131,83.527454,92.717843,108.497407,90.299921'
          />{' '}
          <g transform='translate(112.057558 119.720983) rotate(140.121102) scale(8.454993 11.117764)'>
            <ellipse fill='#749b2f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(195 84) rotate(222) scale(14 72)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(49 48) rotate(149) scale(102 25)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#a5c353'
            fillOpacity='0.501961'
            points='163.281559,35.677499,190.062979,41.764040,183.426157,82.877215,182.752400,57.689590'
          />{' '}
          <ellipse fill='#98be30' fillOpacity='0.501961' cx='143' cy='64' rx='2' ry='19' />{' '}
          <rect fill='#78a12e' fillOpacity='0.501961' x='64' y='56' width='6' height='26' />{' '}
          <ellipse fill='#88b211' fillOpacity='0.501961' cx='104' cy='80' rx='1' ry='7' />{' '}
        </g>
      </g>
    </svg>
  );
};

export const HeliosBanner = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 886 591'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#726e65' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(3.460938) translate(0.5 0.5)'>
          {' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='219' cy='134' rx='41' ry='54' />{' '}
          <g transform='translate(106.504633 145.524488) rotate(285.959376) scale(13.389034 137.851591)'>
            <ellipse fill='#fffff2' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(105.459554 57.953500) rotate(103.933089) scale(14.495594 99.205101)'>
            <ellipse fill='#fff8bb' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(169.073445 23.858162) rotate(375.181196) scale(110.539989 15.834409)'>
            <ellipse fill='#000007' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(216 7) rotate(109) scale(27 92)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(218.266805 129.932733) rotate(268.433263) scale(49.359034 37.403676)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(80.044674 62.274851) rotate(16.477123) scale(109.972605 5.996063)'>
            <ellipse fill='#279ef9' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(227.749365 67.794370) rotate(296.627047) scale(14.491474 58.860119)'>
            <ellipse fill='#c1b1a5' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#00050c'
            fillOpacity='0.501961'
            points='59.618160,-16.000000,257.576763,58.183097,244.962922,5.486627,231.563313,29.490090'
          />{' '}
          <ellipse fill='#fbffff' fillOpacity='0.501961' cx='218' cy='0' rx='39' ry='17' />{' '}
          <polygon fill='#ffc45b' fillOpacity='0.501961' points='132,76 52,27 215,75' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='25.431087,-4.268035,19.923245,21.383483,-16.000000,8.632654,0.035182,-13.799236'
          />{' '}
          <ellipse fill='#000019' fillOpacity='0.501961' cx='60' cy='9' rx='15' ry='15' />{' '}
          <g transform='translate(111 30) rotate(287) scale(18 27)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(30.206970 30.129335) rotate(103.202970) scale(9.148900 35.712897)'>
            <ellipse fill='#ffb65d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='220' cy='115' rx='35' ry='35' />{' '}
          <g transform='translate(138.379919 36.257039) rotate(65.274289) scale(17.350081 12.709001)'>
            <ellipse fill='#b4a99f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#181200'
            fillOpacity='0.501961'
            points='113.183735,80.550810,80.160629,73.475776,98.228882,103.191276,96.362453,123.083135'
          />{' '}
          <g transform='translate(63.412052 133.043316) rotate(12.507625) scale(112.643559 18.590562)'>
            <ellipse fill='#cabeae' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(161 40) rotate(197) scale(29 25)'>
            <rect fill='#000819' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(145.575271 106.003942) rotate(94.757173) scale(20.105379 15.622743)'>
            <ellipse fill='#372d0e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#3e3c3d' fillOpacity='0.501961' cx='8' cy='161' rx='80' ry='17' />{' '}
          <g transform='translate(84 20) rotate(287) scale(30 24)'>
            <rect fill='#c3b3a7' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#383117'
            fillOpacity='0.501961'
            points='66.269363,65.357964,21.966281,50.897631,30.449354,68.574995,30.723647,98.159188'
          />{' '}
          <polygon fill='#b7bab2' fillOpacity='0.501961' points='142,163 184,120 182,34' />{' '}
          <rect fill='#000003' fillOpacity='0.501961' x='174' y='134' width='76' height='25' />{' '}
          <polygon fill='#c0b0a1' fillOpacity='0.501961' points='134,89 108,81 107,164' />{' '}
          <g transform='translate(63 98) rotate(283) scale(61 23)'>
            <rect fill='#bcac9f' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#0099f8' fillOpacity='0.501961' cx='177' cy='89' rx='14' ry='6' />{' '}
          <g transform='translate(10 37) rotate(202) scale(4 73)'>
            <rect fill='#1c0b0f' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(0.000000 25.055773) rotate(252.562724) scale(30.097454 5.322758)'>
            <ellipse fill='#eeb58a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(84 134) rotate(16) scale(96 9)'>
            <rect fill='#f0efea' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#b79d90' fillOpacity='0.501961' points='29,61 -16,137 9,47' />{' '}
          <g transform='translate(83.910777 116.447563) rotate(96.699497) scale(10.722417 21.003542)'>
            <ellipse fill='#3e4e5c' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#ffc18f' fillOpacity='0.501961' points='32,39 80,28 125,71' />{' '}
          <g transform='translate(144 8) rotate(23) scale(83 21)'>
            <rect fill='#00080d' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(33.926139 6.354525) rotate(109.829487) scale(26.441620 10.933199)'>
            <ellipse fill='#d2aa8c' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#aba595' fillOpacity='0.501961' points='179,155 251,179 105,171' />{' '}
          <polygon
            fill='#0a82d8'
            fillOpacity='0.501961'
            points='14.183255,37.764464,25.789461,37.159030,49.648177,59.548145,12.945061,49.865786'
          />{' '}
          <polygon fill='#daf0fe' fillOpacity='0.501961' points='119,-12 224,21 271,-6' />{' '}
          <rect fill='#fffaa1' fillOpacity='0.501961' x='164' y='65' width='26' height='18' />{' '}
          <polygon
            fill='#9a8f87'
            fillOpacity='0.501961'
            points='175.324150,33.623766,204.598175,44.564144,271.000000,63.287218,193.245340,85.618910'
          />{' '}
          <polygon
            fill='#000008'
            fillOpacity='0.501961'
            points='155.063083,122.512375,163.427565,87.070765,147.512754,126.484187,151.359268,137.631499'
          />{' '}
          <g transform='translate(90 93) rotate(15) scale(6 48)'>
            <rect fill='#181f18' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#002e63' fillOpacity='0.501961' points='10,91 47,94 44,105' />{' '}
          <g transform='translate(102 46) rotate(126) scale(11 3)'>
            <rect fill='#1c0000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#2c2a2c' fillOpacity='0.501961' points='143,97 181,95 110,76' />{' '}
          <polygon
            fill='#e7e4d9'
            fillOpacity='0.501961'
            points='174.609148,150.869207,163.773176,166.238321,114.013011,136.007389,127.282556,138.834167'
          />{' '}
          <g transform='translate(105.743340 156.563610) rotate(196.646334) scale(111.960826 6.374025)'>
            <ellipse fill='#a49a87' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#1a1d1e' fillOpacity='0.501961' points='173,44 146,70 177,16' />{' '}
          <ellipse fill='#d8b245' fillOpacity='0.501961' cx='96' cy='98' rx='4' ry='11' />{' '}
          <g transform='translate(244 17) rotate(139) scale(18 7)'>
            <rect fill='#0a0d19' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#d9dfe5' fillOpacity='0.501961' points='271,-16 262,34 243,29' />{' '}
          <polygon
            fill='#c1903d'
            fillOpacity='0.501961'
            points='48.287010,79.544238,41.892939,96.716631,37.363552,87.613257,43.895931,68.263221'
          />{' '}
          <polygon
            fill='#9c8e7c'
            fillOpacity='0.501961'
            points='136.957710,119.555129,139.928137,101.411749,121.607323,75.629782,92.759924,133.328209'
          />{' '}
          <g transform='translate(14 169) rotate(196) scale(33 9)'>
            <rect fill='#000714' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(45 67) rotate(109) scale(7 25)'>
            <rect fill='#000204' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#ae9f93' fillOpacity='0.501961' x='124' y='25' width='24' height='26' />{' '}
          <polygon fill='#c0af6c' fillOpacity='0.501961' points='153,95 143,113 146,127' />{' '}
          <g transform='translate(211.562304 130.004819) rotate(412.427674) scale(30.366283 34.639954)'>
            <ellipse fill='#000104' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#0d3b6c' fillOpacity='0.501961' points='128,115 150,127 124,130' />{' '}
          <g transform='translate(11.641397 1.733966) rotate(136.948715) scale(17.512903 12.198786)'>
            <ellipse fill='#09070f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='-16,118 8,49 -15,45' />{' '}
          <polygon
            fill='#ffc272'
            fillOpacity='0.501961'
            points='174.723094,84.285517,144.098065,78.099203,86.966972,59.418377,127.911467,47.474642'
          />{' '}
          <polygon fill='#0b1b2c' fillOpacity='0.501961' points='35,-9 88,-16 147,24' />{' '}
          <polygon
            fill='#4ab1ed'
            fillOpacity='0.501961'
            points='166.458489,91.857790,196.393351,82.667204,174.647732,85.479271,99.212391,66.617196'
          />{' '}
          <polygon fill='#ffb249' fillOpacity='0.501961' points='23,20 47,28 20,38' />{' '}
          <polygon fill='#3c2824' fillOpacity='0.501961' points='41,39 50,23 56,25' />{' '}
          <g transform='translate(24 79) rotate(198) scale(1 40)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(49.134054 86.322877) rotate(106.141206) scale(19.381135 1.621711)'>
            <ellipse fill='#0e0600' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#f7e8e0'
            fillOpacity='0.501961'
            points='43.100954,28.595215,31.299240,52.726231,30.376472,46.095700,36.334822,33.123137'
          />{' '}
          <polygon
            fill='#e1dfda'
            fillOpacity='0.501961'
            points='-2.039584,116.780422,34.237479,126.801680,53.970776,121.151176,-16.000000,101.770958'
          />{' '}
          <polygon fill='#baa795' fillOpacity='0.501961' points='271,141 249,171 270,92' />{' '}
          <polygon
            fill='#c19830'
            fillOpacity='0.501961'
            points='24.323187,81.322549,39.404139,65.719530,29.193938,91.563028,22.975865,83.721562'
          />{' '}
          <polygon
            fill='#0c100e'
            fillOpacity='0.501961'
            points='85.965462,69.883766,63.317975,63.230393,100.595668,79.230148,80.633891,84.996648'
          />{' '}
          <g transform='translate(5 23) rotate(372) scale(13 12)'>
            <rect fill='#ffefb1' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(251 36) rotate(17) scale(111 1)'>
            <rect fill='#6d7074' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#aa9583' fillOpacity='0.501961' cx='167' cy='132' rx='10' ry='17' />{' '}
          <polygon
            fill='#4f7196'
            fillOpacity='0.501961'
            points='108.577945,11.657230,127.747754,20.429057,138.554607,27.957265,94.818631,14.396662'
          />{' '}
          <polygon
            fill='#8a817f'
            fillOpacity='0.501961'
            points='190.047720,80.254372,198.354411,52.008168,216.245488,74.771433,184.733903,97.781675'
          />{' '}
          <polygon fill='#091f32' fillOpacity='0.501961' points='54,0 67,31 80,-13' />{' '}
          <polygon
            fill='#d99f2f'
            fillOpacity='0.501961'
            points='83.376447,85.645561,89.542165,87.616107,82.395045,105.099911,76.809312,101.675324'
          />{' '}
          <polygon fill='#000507' fillOpacity='0.501961' points='255,171 172,154 198,86' />{' '}
          <polygon
            fill='#128fe0'
            fillOpacity='0.501961'
            points='26.896983,49.101601,11.979633,48.037998,152.801784,87.809010,117.545721,73.818407'
          />{' '}
          <rect fill='#010e21' fillOpacity='0.501961' x='98' y='22' width='26' height='14' />{' '}
          <polygon
            fill='#393d41'
            fillOpacity='0.501961'
            points='264.251805,62.351709,175.575225,34.669310,215.342046,34.599256,267.746383,55.550549'
          />{' '}
          <g transform='translate(101 61) rotate(-12) scale(23 8)'>
            <rect fill='#e7d8ca' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#9c8d7d' fillOpacity='0.501961' points='67,123 58,69 43,114' />{' '}
          <g transform='translate(66 65) rotate(16) scale(119 1)'>
            <rect fill='#000a1f' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#e5eef2'
            fillOpacity='0.501961'
            points='241.991169,-16.000000,267.149982,13.371691,244.412511,11.384905,226.573357,24.015989'
          />{' '}
          <polygon fill='#e5f7ff' fillOpacity='0.501961' points='52,55 56,37 48,50' />{' '}
          <polygon fill='#ffac4b' fillOpacity='0.501961' points='81,57 80,37 52,28' />{' '}
          <g transform='translate(142.102972 110.430203) rotate(194.160261) scale(2.568520 20.977905)'>
            <ellipse fill='#14100d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#cacdce' fillOpacity='0.501961' x='157' y='53' width='7' height='4' />{' '}
          <g transform='translate(136 108) rotate(101) scale(21 7)'>
            <rect fill='#bf8a2d' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(12.293307 35.847986) rotate(98.974785) scale(8.653504 3.049076)'>
            <ellipse fill='#412d28' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(147 95) rotate(17) scale(25 9)'>
            <rect fill='#050800' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#8d7f70'
            fillOpacity='0.501961'
            points='55.037717,111.375074,-16.000000,102.076681,203.024657,157.691878,118.604732,134.118934'
          />{' '}
          <g transform='translate(37 83) rotate(19) scale(3 46)'>
            <rect fill='#0c0709' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='110,81 105,78 106,94' />{' '}
        </g>
      </g>
    </svg>
  );
};

export const HeliosLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1200 800'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#2e2d15' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(4.687500) translate(0.5 0.5)'>
          {' '}
          <ellipse fill='#ffff6b' fillOpacity='0.501961' cx='107' cy='63' rx='31' ry='102' />{' '}
          <ellipse fill='#7abce2' fillOpacity='0.501961' cx='127' cy='143' rx='51' ry='21' />{' '}
          <rect fill='#ffee2f' fillOpacity='0.501961' x='77' y='14' width='56' height='127' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='243' cy='77' rx='95' ry='95' />{' '}
          <g transform='translate(35.589013 81.464363) rotate(91.015053) scale(163.835292 42.822791)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(125 39) rotate(314) scale(85 13)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='127' cy='0' rx='252' ry='7' />{' '}
          <rect fill='#0056cb' fillOpacity='0.501961' x='81' y='142' width='95' height='21' />{' '}
          <polygon fill='#ffff29' fillOpacity='0.501961' points='116,52 112,141 146,143' />{' '}
          <polygon fill='#ffff83' fillOpacity='0.501961' points='77,10 79,74 143,5' />{' '}
          <polygon fill='#ffff8d' fillOpacity='0.501961' points='111,93 111,60 171,13' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='218' cy='111' rx='41' ry='255' />{' '}
          <ellipse fill='#060000' fillOpacity='0.501961' cx='162' cy='80' rx='31' ry='44' />{' '}
          <g transform='translate(34.483545 81.183434) rotate(96.910868) scale(141.162030 40.855818)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#ffff14' fillOpacity='0.501961' x='175' y='92' width='4' height='50' />{' '}
          <polygon fill='#2a0000' fillOpacity='0.501961' points='102,112 98,57 114,76' />{' '}
          <g transform='translate(120 43) rotate(43) scale(10 69)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#fffe2f' fillOpacity='0.501961' x='78' y='8' width='17' height='127' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='116' cy='167' rx='252' ry='6' />{' '}
          <polygon
            fill='#ffff10'
            fillOpacity='0.501961'
            points='173.441484,-16.000000,182.781123,-16.000000,146.079430,38.373991,167.226679,21.596115'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='120' cy='0' rx='255' ry='8' />{' '}
          <polygon fill='#ffff8a' fillOpacity='0.501961' points='124,94 112,55 140,38' />{' '}
          <rect fill='#ffdb8a' fillOpacity='0.501961' x='83' y='126' width='62' height='16' />{' '}
          <polygon
            fill='#ffde00'
            fillOpacity='0.501961'
            points='133.183748,143.843092,136.191001,110.637487,105.002758,75.330489,109.372691,121.710970'
          />{' '}
          <polygon
            fill='#f2d7cf'
            fillOpacity='0.501961'
            points='175.127904,121.925543,177.428754,121.860480,161.830149,126.396364,148.056762,145.609044'
          />{' '}
          <polygon fill='#0060a5' fillOpacity='0.501961' points='171,147 79,164 78,139' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='49' cy='138' rx='29' ry='214' />{' '}
          <ellipse fill='#100000' fillOpacity='0.501961' cx='141' cy='92' rx='8' ry='37' />{' '}
          <rect fill='#ffc700' fillOpacity='0.501961' x='79' y='82' width='22' height='42' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='145,134 151,127 153,140' />{' '}
          <g transform='translate(218.597606 96.449332) rotate(102.232338) scale(103.921425 34.853864)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#fffe69' fillOpacity='0.501961' points='86,7 81,66 143,7' />{' '}
          <g transform='translate(104 106) rotate(10) scale(11 16)'>
            <rect fill='#79090a' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='135' cy='130' rx='2' ry='15' />{' '}
          <polygon
            fill='#24000c'
            fillOpacity='0.501961'
            points='142.045394,10.857871,108.441992,63.148896,105.707664,88.593613,93.151885,60.552933'
          />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='157' y='112' width='3' height='33' />{' '}
          <rect fill='#076eaa' fillOpacity='0.501961' x='78' y='143' width='100' height='18' />{' '}
          <g transform='translate(164.598923 17.066736) rotate(26.074464) scale(3.842049 11.426577)'>
            <ellipse fill='#ffe50f' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='118' cy='168' rx='255' ry='6' />{' '}
          <rect fill='#aebad4' fillOpacity='0.501961' x='100' y='152' width='47' height='3' />{' '}
          <polygon fill='#f2d9d7' fillOpacity='0.501961' points='172,139 162,125 149,126' />{' '}
          <polygon fill='#0f0004' fillOpacity='0.501961' points='131,82 196,0 140,130' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='153.880675,125.880178,156.352446,109.819760,161.711114,140.259437,166.766728,142.318688'
          />{' '}
          <polygon fill='#090000' fillOpacity='0.501961' points='149,31 115,38 120,49' />{' '}
          <polygon fill='#fff66e' fillOpacity='0.501961' points='112,102 115,54 149,33' />{' '}
          <polygon
            fill='#fbdec9'
            fillOpacity='0.501961'
            points='167.807283,133.642096,169.799172,141.340152,107.353998,142.563114,173.440471,143.725337'
          />{' '}
          <polygon fill='#ffec00' fillOpacity='0.501961' points='139,124 86,124 129,98' />{' '}
          <g transform='translate(27.247154 94.191157) rotate(259.287029) scale(119.873118 45.802630)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='135' cy='0' rx='198' ry='7' />{' '}
          <polygon
            fill='#feea13'
            fillOpacity='0.501961'
            points='88.670037,7.064080,143.386024,8.634241,80.871365,24.639903,76.314949,7.225434'
          />{' '}
          <rect fill='#ffeb0f' fillOpacity='0.501961' x='176' y='92' width='2' height='47' />{' '}
          <polygon fill='#fff091' fillOpacity='0.501961' points='87,67 77,42 132,14' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='271,166 243,-16 145,40' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='119' y='127' width='4' height='13' />{' '}
          <polygon fill='#f2d300' fillOpacity='0.501961' points='77,86 94,132 103,75' />{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='164,136 159,130 154,147' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='85' y='132' width='24' height='4' />{' '}
          <g transform='translate(141 134) rotate(310) scale(26 1)'>
            <rect fill='#ffffe2' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='149.336732,148.923904,158.210657,134.611581,153.839030,129.693787,153.252273,140.084399'
          />{' '}
          <polygon
            fill='#9a7109'
            fillOpacity='0.501961'
            points='104.929054,92.158744,89.110598,100.043085,93.397300,94.773781,91.917856,114.032663'
          />{' '}
          <g transform='translate(119 67) rotate(103) scale(16 19)'>
            <rect fill='#fff588' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#ffb100' fillOpacity='0.501961' cx='101' cy='129' rx='2' ry='13' />{' '}
          <polygon
            fill='#1c0607'
            fillOpacity='0.501961'
            points='135.844993,55.092862,177.642650,7.629250,159.899710,132.005431,131.708104,100.751432'
          />{' '}
          <rect fill='#ffdc00' fillOpacity='0.501961' x='122' y='98' width='12' height='43' />{' '}
          <ellipse fill='#96b3d1' fillOpacity='0.501961' cx='155' cy='153' rx='20' ry='2' />{' '}
          <rect fill='#ffe402' fillOpacity='0.501961' x='110' y='90' width='9' height='52' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='237' cy='95' rx='60' ry='214' />{' '}
          <polygon
            fill='#f7e54c'
            fillOpacity='0.501961'
            points='110.594500,88.576352,126.811915,102.269542,138.804840,38.874994,164.279234,22.228985'
          />{' '}
          <g transform='translate(147 47) rotate(-3) scale(4 23)'>
            <rect fill='#1c0002' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='94' y='125' width='21' height='3' />{' '}
          <rect fill='#ffc500' fillOpacity='0.501961' x='78' y='94' width='7' height='46' />{' '}
          <rect fill='#060004' fillOpacity='0.501961' x='157' y='45' width='19' height='65' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='168,123 160,127 169,127' />{' '}
          <rect fill='#00599f' fillOpacity='0.501961' x='80' y='156' width='98' height='6' />{' '}
          <g transform='translate(86 133) rotate(3) scale(3 15)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='148' cy='136' rx='5' ry='5' />{' '}
          <rect fill='#030001' fillOpacity='0.501961' x='138' y='107' width='34' height='18' />{' '}
          <polygon
            fill='#1b0004'
            fillOpacity='0.501961'
            points='156.879981,24.829679,173.903651,-16.000000,116.437889,40.674950,135.340983,41.788654'
          />{' '}
          <polygon fill='#030004' fillOpacity='0.501961' points='105,55 97,74 109,77' />{' '}
          <rect fill='#ffa517' fillOpacity='0.501961' x='174' y='115' width='4' height='23' />{' '}
          <rect fill='#fff07b' fillOpacity='0.501961' x='78' y='28' width='15' height='46' />{' '}
          <g transform='translate(97 154) rotate(-2) scale(27 4)'>
            <rect fill='#7ca0c4' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#4c2111'
            fillOpacity='0.501961'
            points='102.238345,87.043354,110.261249,88.546688,95.727915,103.372136,104.288978,112.808460'
          />{' '}
          <polygon
            fill='#ffe300'
            fillOpacity='0.501961'
            points='122.575607,113.747292,77.455484,112.786195,77.862830,69.195778,99.203738,128.587016'
          />{' '}
          <polygon
            fill='#00589f'
            fillOpacity='0.501961'
            points='178.493317,141.814774,142.380008,146.754750,172.644851,148.277720,177.359073,165.491464'
          />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='93' y='163' width='93' height='7' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='271.000000,7.850462,179.481277,-10.503403,167.614349,20.273435,252.594543,185.000000'
          />{' '}
          <rect fill='#050000' fillOpacity='0.501961' x='145' y='130' width='9' height='10' />{' '}
          <g transform='translate(142 137) rotate(161) scale(4 8)'>
            <rect fill='#fffff9' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(139 136) rotate(173) scale(1 14)'>
            <rect fill='#ffb900' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#ffb204' fillOpacity='0.501961' points='100,103 82,116 94,132' />{' '}
          <g transform='translate(165 129) rotate(439) scale(3 3)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#005798' fillOpacity='0.501961' x='78' y='144' width='97' height='8' />{' '}
          <g transform='translate(165 12) rotate(103) scale(12 1)'>
            <rect fill='#ffff0c' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(105 132) rotate(188) scale(4 13)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(135.369779 119.404833) rotate(151.211645) scale(2.576921 1.489534)'>
            <ellipse fill='#fffeff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='114' cy='0' rx='141' ry='7' />{' '}
          <ellipse fill='#ffff0f' fillOpacity='0.501961' cx='87' cy='89' rx='9' ry='14' />{' '}
          <ellipse fill='#ff8300' fillOpacity='0.501961' cx='91' cy='138' rx='3' ry='3' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='23' cy='44' rx='54' ry='255' />{' '}
        </g>
      </g>
    </svg>
  );
};

export const KoffieNBanner = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1181 787'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#939899' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(4.613281) translate(0.5 0.5)'>
          {' '}
          <g transform='translate(67.920367 4.973760) rotate(180.449516) scale(198.812195 40.286293)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='169,179 122,86 -13,168' />{' '}
          <g transform='translate(207.569173 117.240150) rotate(90.365645) scale(28.015353 41.921852)'>
            <ellipse fill='#3a3200' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(38.259683 24.816670) rotate(161.652107) scale(168.531925 28.436351)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(60.664305 130.095742) rotate(161.037700) scale(33.297842 16.504894)'>
            <ellipse fill='#a9bac9' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(205 169) rotate(184) scale(113 13)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(109.917790 40.914177) rotate(259.158149) scale(5.444656 70.702209)'>
            <ellipse fill='#394144' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(113.917407 113.314683) rotate(61.269627) scale(17.021724 27.607388)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#b0becd' fillOpacity='0.501961' cx='158' cy='69' rx='91' ry='27' />{' '}
          <g transform='translate(225.283683 1.278346) rotate(256.012390) scale(27.998060 33.073269)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(235 34) rotate(-18) scale(62 10)'>
            <rect fill='#0c0b06' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <rect fill='#b0bbc6' fillOpacity='0.501961' x='137' y='40' width='31' height='111' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='-13,71 187,-2 -1,-6' />{' '}
          <g transform='translate(39 119) rotate(2) scale(11 27)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#20261c' fillOpacity='0.501961' cx='20' cy='160' rx='139' ry='15' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='148' y='110' width='8' height='21' />{' '}
          <g transform='translate(173.400768 19.399224) rotate(69.119983) scale(2.880471 22.774535)'>
            <ellipse fill='#181c1d' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#c0d0dd' fillOpacity='0.501961' x='41' y='133' width='111' height='6' />{' '}
          <polygon
            fill='#1d1d16'
            fillOpacity='0.501961'
            points='111.726273,33.218007,72.507055,54.289880,87.737516,31.717677,106.168559,45.928300'
          />{' '}
          <rect fill='#5d5a36' fillOpacity='0.501961' x='166' y='92' width='79' height='52' />{' '}
          <rect fill='#141f23' fillOpacity='0.501961' x='90' y='73' width='27' height='9' />{' '}
          <ellipse fill='#000401' fillOpacity='0.501961' cx='107' cy='148' rx='33' ry='11' />{' '}
          <rect fill='#070c00' fillOpacity='0.501961' x='90' y='97' width='47' height='35' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='265,-16 143,24 158,-16' />{' '}
          <rect fill='#1b1f1a' fillOpacity='0.501961' x='71' y='99' width='11' height='29' />{' '}
          <polygon
            fill='#323c3e'
            fillOpacity='0.501961'
            points='34.401726,67.280252,-7.813736,75.338102,9.820615,64.795872,65.331996,55.374975'
          />{' '}
          <polygon
            fill='#ced9e7'
            fillOpacity='0.501961'
            points='85.650228,120.141689,88.315388,108.750203,70.673341,106.818507,70.962682,111.892457'
          />{' '}
          <g transform='translate(47.123011 137.705672) rotate(258.636549) scale(11.744183 21.903803)'>
            <ellipse fill='#9da9b6' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='118' y='22' width='6' height='5' />{' '}
          <g transform='translate(226 12) rotate(240) scale(26 63)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(39.057906 87.302633) rotate(82.957958) scale(19.678221 52.863192)'>
            <ellipse fill='#a4b4c4' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#19170e'
            fillOpacity='0.501961'
            points='59.438750,39.747245,63.325349,56.445533,62.907869,58.527472,76.005223,49.889313'
          />{' '}
          <polygon fill='#c8cc90' fillOpacity='0.501961' points='201,104 170,116 170,103' />{' '}
          <g transform='translate(188.623803 45.117707) rotate(174.421092) scale(17.457576 33.222592)'>
            <ellipse fill='#8a949e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#1a1e0f' fillOpacity='0.501961' x='194' y='109' width='41' height='14' />{' '}
          <polygon fill='#aab5c7' fillOpacity='0.501961' points='255,153 233,48 271,37' />{' '}
          <ellipse fill='#afbed2' fillOpacity='0.501961' cx='197' cy='153' rx='41' ry='8' />{' '}
          <polygon
            fill='#554924'
            fillOpacity='0.501961'
            points='246.172716,90.320082,237.375873,85.244037,166.272403,99.885199,238.555851,98.727806'
          />{' '}
          <polygon
            fill='#b7c2d0'
            fillOpacity='0.501961'
            points='69.954324,145.317091,87.207793,141.370164,86.348611,128.230731,61.571688,144.305617'
          />{' '}
          <rect fill='#b3bfc8' fillOpacity='0.501961' x='125' y='48' width='2' height='90' />{' '}
          <polygon fill='#636f6c' fillOpacity='0.501961' points='122,94 108,82 90,99' />{' '}
          <g transform='translate(50.773094 25.900759) rotate(242.775413) scale(21.267952 67.120469)'>
            <ellipse fill='#ffffff' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#4c504a'
            fillOpacity='0.501961'
            points='23.609183,137.751196,69.645803,185.000000,197.820388,165.028773,-5.968773,155.274107'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='142' cy='16' rx='3' ry='3' />{' '}
          <polygon
            fill='#454537'
            fillOpacity='0.501961'
            points='244.850660,146.548187,196.676952,140.908858,247.258704,127.526106,249.893259,139.393065'
          />{' '}
          <g transform='translate(238 42) rotate(167) scale(87 7)'>
            <rect fill='#59646c' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='140,34 121,37 137,-6' />{' '}
          <rect fill='#d9d993' fillOpacity='0.501961' x='187' y='107' width='6' height='20' />{' '}
          <g transform='translate(147.710945 33.364954) rotate(250.871072) scale(7.251556 33.301755)'>
            <ellipse fill='#768086' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#aeb9c6' fillOpacity='0.501961' points='166,109 170,144 139,147' />{' '}
          <g transform='translate(119.545598 43.303150) rotate(343.022774) scale(65.963963 1.196703)'>
            <ellipse fill='#545a5a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#96a0a6' fillOpacity='0.501961' x='114' y='92' width='2' height='39' />{' '}
          <g transform='translate(216 115) rotate(287) scale(7 6)'>
            <rect fill='#eae9a1' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='210,31 271,17 170,-16' />{' '}
          <polygon
            fill='#a9a983'
            fillOpacity='0.501961'
            points='231.201791,117.438688,259.715027,121.619776,240.927510,110.614558,242.204876,95.753893'
          />{' '}
          <polygon
            fill='#aeb8c1'
            fillOpacity='0.501961'
            points='229.017342,81.809056,169.424328,35.308719,97.335575,47.218496,139.585978,102.848510'
          />{' '}
          <g transform='translate(74 104) rotate(78) scale(9 21)'>
            <rect fill='#283134' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(220.455633 166.708043) rotate(273.856830) scale(2.484487 65.763995)'>
            <ellipse fill='#061113' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <g transform='translate(152 119) rotate(250) scale(13 4)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#586262' fillOpacity='0.501961' points='105,62 114,48 104,23' />{' '}
          <polygon
            fill='#2d2c23'
            fillOpacity='0.501961'
            points='146.910078,27.727901,189.003450,10.357874,178.657078,20.638510,154.270123,25.118906'
          />{' '}
          <g transform='translate(0 121) rotate(365) scale(13 22)'>
            <rect fill='#3c4e45' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#b8b778' fillOpacity='0.501961' cx='225' cy='130' rx='4' ry='10' />{' '}
          <ellipse fill='#f7fafc' fillOpacity='0.501961' cx='73' cy='33' rx='12' ry='12' />{' '}
          <polygon
            fill='#030300'
            fillOpacity='0.501961'
            points='34.186974,122.862029,41.757538,108.599835,43.418790,104.945490,43.762299,128.009177'
          />{' '}
          <g transform='translate(76 120) rotate(89) scale(11 7)'>
            <rect fill='#000000' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#989974'
            fillOpacity='0.501961'
            points='203.162030,96.251343,202.862231,83.388327,218.461209,154.655963,209.212858,126.267190'
          />{' '}
          <polygon
            fill='#adb7c2'
            fillOpacity='0.501961'
            points='148.653573,142.393392,206.890574,146.421223,140.255957,144.603468,132.864168,69.883010'
          />{' '}
          <g transform='translate(244.365587 61.260876) rotate(171.660740) scale(55.568006 1.000000)'>
            <ellipse fill='#60676a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#c4c486' fillOpacity='0.501961' x='170' y='106' width='6' height='21' />{' '}
          <rect fill='#6e7773' fillOpacity='0.501961' x='34' y='70' width='11' height='24' />{' '}
          <ellipse fill='#001631' fillOpacity='0.501961' cx='180' cy='118' rx='6' ry='6' />{' '}
          <g transform='translate(136.341088 64.492957) rotate(49.551183) scale(6.408770 3.312691)'>
            <ellipse fill='#5c605e' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#000000' fillOpacity='0.501961' points='120,21 115,25 126,24' />{' '}
          <polygon
            fill='#0b0d04'
            fillOpacity='0.501961'
            points='117.441049,102.261397,95.019133,120.997352,89.520924,101.089392,143.393708,93.069904'
          />{' '}
          <g transform='translate(31 62) rotate(64) scale(1 51)'>
            <rect fill='#aeb9c7' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(166 12) rotate(64) scale(11 40)'>
            <rect fill='#ffffff' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#afb8c4'
            fillOpacity='0.501961'
            points='83.861106,62.478448,101.739916,72.921545,66.056232,85.961421,90.692892,102.539464'
          />{' '}
          <rect fill='#a0abb5' fillOpacity='0.501961' x='81' y='106' width='10' height='25' />{' '}
          <rect fill='#aaa673' fillOpacity='0.501961' x='180' y='102' width='47' height='3' />{' '}
          <polygon
            fill='#2d3535'
            fillOpacity='0.501961'
            points='-16.000000,146.155545,30.888946,185.000000,25.878343,129.964466,13.889420,149.367245'
          />{' '}
          <polygon
            fill='#afbbc9'
            fillOpacity='0.501961'
            points='60.116565,54.413184,50.260053,63.067898,43.350751,101.776644,49.119277,148.101744'
          />{' '}
          <polygon fill='#666e6b' fillOpacity='0.501961' points='116,84 135,93 90,96' />{' '}
          <rect fill='#3b3e42' fillOpacity='0.501961' x='172' y='137' width='69' height='7' />{' '}
          <rect fill='#8ea1b2' fillOpacity='0.501961' x='5' y='105' width='30' height='31' />{' '}
          <polygon fill='#b6c0ca' fillOpacity='0.501961' points='163,116 122,84 112,24' />{' '}
          <polygon
            fill='#403f3c'
            fillOpacity='0.501961'
            points='215.961348,32.599549,271.000000,20.095125,192.538687,51.871466,199.967996,45.555266'
          />{' '}
          <g transform='translate(90 37) rotate(112) scale(1 14)'>
            <rect fill='#010400' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(26 107) rotate(29) scale(7 2)'>
            <rect fill='#080a01' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(6.274021 67.747911) rotate(339.719183) scale(55.209346 1.000000)'>
            <ellipse fill='#4a4e4a' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon fill='#0c0f11' fillOpacity='0.501961' points='50,149 123,138 80,157' />{' '}
          <g transform='translate(38.516062 62.323192) rotate(249.827346) scale(2.439504 19.875947)'>
            <ellipse fill='#3d4343' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <polygon
            fill='#feffff'
            fillOpacity='0.501961'
            points='125.357155,-16.000000,165.895703,17.864523,70.701053,-0.808767,97.415046,43.464243'
          />{' '}
          <rect fill='#515451' fillOpacity='0.501961' x='143' y='127' width='14' height='5' />{' '}
          <polygon fill='#5d6455' fillOpacity='0.501961' points='4,77 8,94 1,97' />{' '}
          <ellipse fill='#98976d' fillOpacity='0.501961' cx='220' cy='125' rx='52' ry='2' />{' '}
          <ellipse fill='#a0acba' fillOpacity='0.501961' cx='237' cy='153' rx='79' ry='10' />{' '}
          <polygon
            fill='#010500'
            fillOpacity='0.501961'
            points='227.388567,108.450300,214.270827,105.196536,209.066595,111.249482,234.937518,103.056801'
          />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='147' y='110' width='9' height='14' />{' '}
          <polygon
            fill='#96a1ac'
            fillOpacity='0.501961'
            points='59.664222,110.209269,24.005062,147.113172,55.531320,148.114312,80.954861,106.996441'
          />{' '}
        </g>
      </g>
    </svg>
  );
};

export const KoffieNLogo = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1200 800'>
      <filter id='b'>
        <feGaussianBlur stdDeviation='50' />
      </filter>{' '}
      <rect width='100%' height='100%' fill='#605f5e' />{' '}
      <g filter='url(#b)'>
        <g transform='scale(4.687500) translate(0.5 0.5)'>
          {' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='127' cy='83' rx='60' ry='255' />{' '}
          <g transform='translate(231.554168 124.906388) rotate(265.541788) scale(194.406887 42.488413)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='33' cy='85' rx='36' ry='255' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='128' cy='30' rx='60' ry='60' />{' '}
          <g transform='translate(127.195514 121.853649) rotate(70.536264) scale(34.193383 40.346741)'>
            <ellipse fill='#362f30' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='33' cy='105' rx='35' ry='255' />{' '}
          <g transform='translate(225.970549 71.006997) rotate(272.948407) scale(178.587659 37.946418)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='174' cy='95' rx='13' ry='246' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='79' cy='86' rx='11' ry='205' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='129' cy='162' rx='58' ry='6' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='231' cy='66' rx='45' ry='255' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='29' cy='86' rx='39' ry='255' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='161' cy='169' rx='161' ry='1' />{' '}
          <ellipse fill='#4b8600' fillOpacity='0.501961' cx='101' cy='73' rx='11' ry='11' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='0' width='256' height='1' />{' '}
          <polygon
            fill='#2f0000'
            fillOpacity='0.501961'
            points='84.053254,146.455603,107.019395,149.439363,98.530592,129.015167,86.981148,129.786280'
          />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='176' cy='112' rx='11' ry='111' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='70,170 69,-4 99,89' />{' '}
          <ellipse fill='#51545d' fillOpacity='0.501961' cx='142' cy='136' rx='22' ry='22' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='189,1 80,31 71,1' />{' '}
          <polygon fill='#ffc312' fillOpacity='0.501961' points='163,107 161,86 143,88' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='129' cy='54' rx='58' ry='7' />{' '}
          <polygon
            fill='#000000'
            fillOpacity='0.501961'
            points='195.013269,167.001571,122.186919,170.289879,271.000000,175.140439,186.418876,-16.000000'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='28' cy='36' rx='40' ry='255' />{' '}
          <ellipse fill='#ffffff' fillOpacity='0.501961' cx='119' cy='163' rx='50' ry='5' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='74.357141,118.356859,111.120498,88.060225,69.769407,69.000979,71.325088,157.974173'
          />{' '}
          <ellipse fill='#4c4d51' fillOpacity='0.501961' cx='138' cy='117' rx='32' ry='9' />{' '}
          <polygon
            fill='#fffffd'
            fillOpacity='0.501961'
            points='187.877688,63.097590,186.179951,0.080639,133.291497,63.773107,74.510068,102.837701'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='230' cy='34' rx='43' ry='255' />{' '}
          <polygon
            fill='#edbb00'
            fillOpacity='0.501961'
            points='103.179899,83.654460,108.786522,67.769824,90.892890,73.384085,91.679228,77.876286'
          />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='148,57 186,40 187,161' />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='108' cy='0' rx='160' ry='1' />{' '}
          <rect fill='#474347' fillOpacity='0.501961' x='94' y='143' width='16' height='14' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='0' y='169' width='203' height='1' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='69' y='147' width='25' height='22' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='83' y='2' width='104' height='17' />{' '}
          <g transform='translate(100 66) rotate(344) scale(18 5)'>
            <rect fill='#007fec' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#4d4c50'
            fillOpacity='0.501961'
            points='147.970278,143.841498,169.728357,142.417454,153.911028,129.196987,166.073331,154.977801'
          />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='177,150 119,164 179,168' />{' '}
          <rect fill='#8a8d93' fillOpacity='0.501961' x='104' y='133' width='40' height='20' />{' '}
          <polygon fill='#caac8e' fillOpacity='0.501961' points='157,79 124,83 149,92' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='69' y='49' width='18' height='92' />{' '}
          <polygon
            fill='#fffdf6'
            fillOpacity='0.501961'
            points='158.257136,103.614634,185.603813,122.430861,187.935970,93.202628,185.344375,35.322627'
          />{' '}
          <polygon
            fill='#91959d'
            fillOpacity='0.501961'
            points='152.722067,109.564730,148.132451,122.243641,113.642449,100.895832,151.618955,100.093953'
          />{' '}
          <polygon
            fill='#ff7e00'
            fillOpacity='0.501961'
            points='155.297540,88.191412,147.328857,95.666115,169.592070,102.462860,164.661341,98.767407'
          />{' '}
          <polygon fill='#460800' fillOpacity='0.501961' points='103,143 79,145 91,137' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='69' y='6' width='25' height='58' />{' '}
          <polygon
            fill='#afb0b1'
            fillOpacity='0.501961'
            points='139.182616,26.873830,155.686037,4.437357,154.324546,14.588659,136.116125,48.705735'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='37' cy='151' rx='31' ry='255' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='110.453031,59.211888,118.326267,75.041599,135.673997,51.260764,66.645441,45.726037'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='151' cy='47' rx='9' ry='1' />{' '}
          <g transform='translate(95 35) rotate(270) scale(25 1)'>
            <rect fill='#09090c' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='199' cy='156' rx='12' ry='255' />{' '}
          <polygon fill='#a89992' fillOpacity='0.501961' points='128,57 127,76 139,57' />{' '}
          <polygon
            fill='#a9aaab'
            fillOpacity='0.501961'
            points='114.063927,29.854091,122.953216,47.005063,113.982507,42.465992,100.785978,19.672938'
          />{' '}
          <g transform='translate(158 76) rotate(278) scale(23 1)'>
            <rect fill='#ff930f' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='101.148728,164.564952,88.213580,169.059551,188.439961,166.401448,111.771068,154.287237'
          />{' '}
          <g transform='translate(95 113) rotate(86) scale(13 13)'>
            <rect fill='#83878e' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#54342e'
            fillOpacity='0.501961'
            points='94.170614,158.250971,107.161431,131.888424,84.675861,131.370259,100.301132,156.370097'
          />{' '}
          <ellipse fill='#000000' fillOpacity='0.501961' cx='120' cy='0' rx='69' ry='1' />{' '}
          <rect fill='#cfbe00' fillOpacity='0.501961' x='95' y='72' width='14' height='9' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='69.078936,166.234437,162.445448,156.871090,187.423607,99.885856,184.552930,168.513581'
          />{' '}
          <g transform='translate(144 75) rotate(315) scale(1 26)'>
            <rect fill='#696a6c' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#ffd3a2'
            fillOpacity='0.501961'
            points='174.996988,73.301043,159.262168,98.782739,176.756329,88.046871,144.725563,91.856826'
          />{' '}
          <polygon
            fill='#eb9228'
            fillOpacity='0.501961'
            points='160.599413,100.854902,156.048290,104.412538,144.950681,52.614400,154.665800,88.186064'
          />{' '}
          <g transform='translate(235.636097 53.294903) rotate(188.597265) scale(40.063635 182.760759)'>
            <ellipse fill='#000000' fillOpacity='0.501961' cx='0' cy='0' rx='1' ry='1' />
          </g>{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='61' y='169' width='129' height='1' />{' '}
          <g transform='translate(117 116) rotate(248) scale(6 28)'>
            <rect fill='#323237' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#e3b82d'
            fillOpacity='0.501961'
            points='99.285000,67.809393,94.522515,70.567249,162.352243,100.418115,129.786336,85.079416'
          />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='160' y='2' width='27' height='54' />{' '}
          <ellipse fill='#808181' fillOpacity='0.501961' cx='164' cy='1' rx='23' ry='1' />{' '}
          <rect fill='#7c7d7d' fillOpacity='0.501961' x='70' y='168' width='50' height='1' />{' '}
          <polygon
            fill='#817979'
            fillOpacity='0.501961'
            points='93.993414,66.629040,97.565657,80.743130,100.155417,86.447660,88.725715,80.068153'
          />{' '}
          <polygon
            fill='#58585b'
            fillOpacity='0.501961'
            points='149.477592,121.384662,165.018011,151.849817,166.151467,151.362354,147.726541,155.720425'
          />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='83.130909,145.992340,105.002061,168.604978,69.385582,159.649964,69.579094,93.806473'
          />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='113.830030,47.080381,112.950519,24.623188,107.453220,54.284453,101.377613,45.662359'
          />{' '}
          <ellipse fill='#828383' fillOpacity='0.501961' cx='98' cy='1' rx='30' ry='1' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='154,2 112,1 140,25' />{' '}
          <g transform='translate(110 25) rotate(367) scale(1 12)'>
            <rect fill='#525254' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#0076c3' fillOpacity='0.501961' points='104,60 110,73 111,68' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='124.389278,38.517305,128.839059,19.187304,143.664389,82.889955,147.220220,54.189823'
          />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='85,165 96,146 75,146' />{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='114,76 116,62 135,85' />{' '}
          <polygon
            fill='#ffffff'
            fillOpacity='0.501961'
            points='166.190578,121.692610,184.883570,141.476172,146.445194,158.980045,173.186221,166.383612'
          />{' '}
          <g transform='translate(103 72) rotate(63) scale(6 10)'>
            <rect fill='#ffff00' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(130 63) rotate(162) scale(5 3)'>
            <rect fill='#440b02' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#59595d' fillOpacity='0.501961' points='152,139 143,104 169,142' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='173' y='35' width='14' height='133' />{' '}
          <g transform='translate(134 78) rotate(39) scale(35 1)'>
            <rect fill='#ca9e6b' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon
            fill='#8b8e95'
            fillOpacity='0.501961'
            points='86.545904,122.161974,147.129545,77.911866,102.388785,120.977599,156.707643,162.537554'
          />{' '}
          <g transform='translate(141 42) rotate(362) scale(36 3)'>
            <rect fill='#a4a4a6' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <g transform='translate(126 149) rotate(98) scale(14 32)'>
            <rect fill='#68686d' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
          <polygon fill='#ffffff' fillOpacity='0.501961' points='91,129 79,75 75,128' />{' '}
          <polygon
            fill='#fffaf6'
            fillOpacity='0.501961'
            points='171.211723,-2.181815,157.291061,87.420188,104.522238,86.093999,159.836483,89.718791'
          />{' '}
          <polygon
            fill='#5b5858'
            fillOpacity='0.501961'
            points='132.415571,133.853974,171.169090,118.683573,164.735932,116.977700,165.650966,106.796640'
          />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='143' y='29' width='4' height='17' />{' '}
          <rect fill='#000000' fillOpacity='0.501961' x='99' y='29' width='1' height='13' />{' '}
          <rect fill='#ffffff' fillOpacity='0.501961' x='69' y='2' width='25' height='62' />{' '}
          <ellipse fill='#525253' fillOpacity='0.501961' cx='187' cy='86' rx='1' ry='98' />{' '}
          <g transform='translate(106 39) rotate(419) scale(17 1)'>
            <rect fill='#606062' fillOpacity='0.501961' x='-0.5' y='-0.5' width='1' height='1' />
          </g>{' '}
        </g>
      </g>
    </svg>
  );
};
