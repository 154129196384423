import React from 'react';
import Loader from '../loader';

const Map = ({ q }) => {
  return (
    <div className='partner-block'>
      {q ? (
        <iframe
          title='Google Map'
          style={{ border: 'none', width: '100%', height: '100%' }}
          frameBorder='0'
          src={`https://www.google.com/maps/embed/v1/place?q=${q}&key=AIzaSyCyCpOnIm_HYfWQ-5BhB0y81ZE0-9eoIQc`}
          allowFullScreen
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Map;
