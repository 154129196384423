import React, { Component } from 'react';
import { withRouter, Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import Home from './components/topLevel/home';
import Partners from './components/topLevel/partners';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/fr/:alias' render={props => <Partners {...props} lang='fr' />} />
          <Route exact path='/:alias' render={props => <Partners {...props} lang='nl' />} />
          <Route exact path='/' render={props => <Home {...props} />} />
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withRouter(App);
