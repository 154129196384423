const keys = {
  name: 'Naam',
  mail: 'E-mail',
  phone: 'Tel./GSM',
  company: 'Bedrijf',
  message: 'Bericht'
};

export function getTranslation(errors) {
  let output = {};
  Object.keys(errors).map(key => {
    const value = errors[key];
    if (value.includes('empty')) output['empty'] = `Er zijn verplichte velden`;
    return 1;
  });
  Object.keys(errors).map(key => {
    const value = errors[key];

    if (value.includes('length')) {
      let number = '';
      value.match(/\d/g).forEach(e => {
        number += e + '';
      });
      output[key] = `"${keys[key]}" moet min. ${number} tekens bevatten`;
    } else if (value.includes('valid phonenumber')) output[key] = `"${keys[key]}" is geen geldig telefoonnummer`;
    else if (value.includes('valid email')) output[key] = `"${keys[key]}" is geen geldige email`;

    return 1;
  });
  return output;
}
