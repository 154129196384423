import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';
import ContactForm from '../form/contactForm';
import styles from '../../styles/contact.module.scss';
import { CheckMark } from '../../images/icons';

class Contact extends Component {
  state = {};
  render() {
    const { size } = this.props;

    return (
      <div className={styles.contact} style={{ height: size.height }}>
        <div className={styles.left}>
          <div className={styles.highlight}>
            <div className={styles.container}>
              <div className={styles.content}>
                <p className='title'>Zet uw zaak</p>
                <p className='title margin'>in the picture</p>
                <p className='subtitle'>Vanaf € 250/Jaar !</p>
              </div>
            </div>
          </div>
          {/* <LazyLoad offset={50}> */}
          <div className={`${styles.largeBlock} contact-image-1`}></div>
          {/* </LazyLoad> */}
        </div>

        {/* <LazyLoad offset={50}> */}
        <div className={`${styles.right} contact-image-2`}>
          <div className={styles.highlight + ' ' + styles.small}>
            <div className={styles.container}>
              <div className={styles.content}>
                <p className='title margin'>Fotoshoot uw zaak</p>
                <p className='subtitle'>Door een professionele fotograaf</p>
              </div>
            </div>
          </div>

          <div className={styles.highlight}>
            <div className={styles.container + ' ' + styles.light}>
              <div className={styles.content}>
                <p className='small-title margin'>Uw zaak in beeld !</p>
                <div className={styles.list}>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Eén uur foto's van uw zaak</p>
                  </div>
                  <br />
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Door een professionele fotograaf</p>
                  </div>
                  <br />
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>
                      Foto's mogen gebruikt worden
                      <br />
                      voor social media
                    </p>
                  </div>
                  <br />
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>
                      Alle beelden worden vrijgegeven,
                      <br />
                      voorzien van een discrete banner
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.highlight + ' ' + styles.small}>
            <div className={styles.container}>
              <div className={styles.content}>
                <p className='title margin'>Uw webpagina</p>
                <p className='subtitle'>Online zichtbaar voor anderen</p>
              </div>
            </div>
          </div>

          <div className={styles.highlight}>
            <div className={styles.container + ' ' + styles.light}>
              <div className={styles.content}>
                <p className='small-title margin'>Uw eigen pagina</p>
                <div className={styles.list}>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Uw eigen logo</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Beschrijving</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Openingsuren</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Ligging met Google Maps</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Contactgegevens</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Website</p>
                  </div>
                  <div className={styles.listItem}>
                    <CheckMark />
                    <p className='small-text'>Fotoreportage</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.highlight + ' ' + styles.small}>
            <div className={styles.container}>
              <div className={styles.content}>
                <p className='title margin'>Contacteer ons</p>
                <p className='subtitle'>vrijblijvend voor een gesprek</p>
              </div>
            </div>
          </div>

          <div id='contact' className={styles.form}>
            <div className={styles.container + ' ' + styles.light}>
              <div className={styles.content}>
                <p className='small-title margin'>Zet uw zaak online</p>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        {/* </LazyLoad> */}
      </div>
    );
  }
}

export default Contact;
