import React, { Component } from 'react';
import _ from 'lodash';
import styles from '../../styles/search.module.scss';
import { SearchIcon } from '../../images/icons';
import { getFilters } from '../../services/partnerService';
import Filter from './filter';

class Search extends Component {
  state = {
    open: false,
    filters: getFilters(),
    activeFilters: [],
    input: ''
  };

  openClose = () => {
    this.setState({ open: !this.state.open });
  };

  inputChange = ({ target }) => {
    this.setState({ input: target.value });
    this.props.search(target.value, this.state.activeFilters);
  };

  switchActiveFilter = (filter, active) => {
    const { input, activeFilters } = this.state;
    const newActiveFilters = active
      ? _.concat(activeFilters, filter)
      : _.filter(activeFilters, f => f.filter !== filter.filter);
    this.setState({ activeFilters: newActiveFilters });

    this.props.search(input, newActiveFilters);
  };

  render() {
    return (
      <div className={styles.search + ' ' + (this.state.open ? styles.open : '')}>
        <div className={styles.wrapper}>
          <p className='large-title blue margin'>Zoek een handelaar</p>
          <input
            className={styles.searchBar}
            name='search'
            type='text'
            placeholder='Zoek handelaar ...'
            onChange={event => this.inputChange(event)}
          />
          <div className={styles.filters}>
            <p className='large-title blue margin'>Filters</p>
            {this.state.filters.map(filter => (
              <Filter key={filter.filter} name={filter.filter} switch={this.switchActiveFilter} />
            ))}
          </div>
        </div>
        <div className={styles.searchIcon} onClick={() => this.openClose()}>
          <SearchIcon />
        </div>
      </div>
    );
  }
}

export default Search;
