import React from 'react';

const Picture = ({ src, ext, alt, extraClass, ...rest }) => {
  const webp = exists(`${src}.webp`);
  // const webp2x = exists(`${src}@2x.webp`);

  // const j2k = exists(`${src}.j2k`);
  // const j2k2x = exists(`${src}@2x.j2k`);

  // const jxr = exists(`${src}.jxr`);
  // const jxr2x = exists(`${src}@2x.jxr`);

  const std = exists(`${src}.${ext}`);
  const std2x = exists(`${src}@2x.${ext}`);

  return (
    <picture>
      {/* {!(webp && webp2x) || <source type='image/webp' srcSet={`${webp2x} 2x, ${webp} 1x`} />} */}
      {!webp || <source type='image/webp' srcSet={`${webp}`} />}
      {/* {!(j2k && j2k2x) || <source type='image/jk2' srcSet={`${j2k2x} 2x, ${j2k} 1x`} />}
      {!(jxr && jxr2x) || <source type='image/jxr' srcSet={`${jxr2x} 2x, ${jxr} 1x`} />} */}
      {!(std && std2x) || <source srcSet={`${std2x} 2x, ${std} 1x`} />}
      <img {...rest} src={std2x} alt={alt} className={`image-show ${extraClass}`} />
    </picture>
  );
};

function exists(img) {
  try {
    return require(`../../images/${img}`);
  } catch {
    return false;
  }
}

export default Picture;
