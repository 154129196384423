import React from 'react';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (response) => {
    // Status code in 2xx range
    console.log('AXIOS', response);
    return response;
  },
  (error) => {
    console.log('AXIOS ERR', error);
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
};
