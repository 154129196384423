import React from 'react';
import styles from '../../../styles/highlight.module.scss';

const Highlight = ({ size, children, light }) => {
  return (
    <div className='block' style={size ? { width: size.width, height: size.height } : {}}>
      <div className={styles.container + ' ' + (light ? styles.light : '')}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Highlight;
