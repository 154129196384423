import React from 'react';

const TextArea = ({ name, error, ...rest }) => {
  return (
    <React.Fragment>
      <textarea {...rest} name={name} id={name}></textarea>
    </React.Fragment>
  );
};

export default TextArea;
