import React, { Component } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { getTranslation } from '../../services/errorTranslation';
import Joi from 'joi-browser';
import _ from 'lodash';
import Input from './input';
import TextArea from './textArea';

class Form extends Component {
  state = {
    data: {},
    errors: {},
    loading: false
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.object(this.schema).validate(this.state.data, options);

    const errors = {};
    if (error && error.details) for (let item of error.details) errors[item.path[0]] = item.message;

    const phone =
      this.state.data.phone === ''
        ? '"phone" is not allowed to be empty'
        : isValidPhoneNumber(this.state.data.phone)
        ? ''
        : '"phone" is not a valid phonenumber.';
    if (phone !== '') errors['phone'] = phone;

    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { name: value };
    const schema = { name: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault(); // Prevent page reload when form submitting

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (!_.isEmpty(errors)) {
      toast.error(
        <React.Fragment>
          <p style={{ marginBottom: '5px' }}>
            <strong>Oeps!</strong> Het verzenden van het contactformulier is niet gelukt.
          </p>
          {Object.keys(getTranslation(errors)).map(key => (
            <p key={key} style={{ marginLeft: '5px' }}>
              &bull; {getTranslation(errors)[key]}
            </p>
          ))}
        </React.Fragment>
      );
      return;
    }

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    var textarea = document.getElementById('message');
    textarea.scrollTop = textarea.scrollHeight;

    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  handlePhoneChange = input => {
    const errors = { ...this.state.errors };
    const errorMessage =
      this.validateProperty(input) || isValidPhoneNumber(input.value) ? null : '"phone" is not a valid number.';

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderButton(label, { ...rest }) {
    return (
      <div disabled={this.validate()} {...rest}>
        {this.state.loading ? (
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          label
        )}
      </div>
    );
  }

  renderTextarea(name, { ...rest }) {
    const { data, errors } = this.state;
    return <TextArea name={name} value={data[name]} error={errors[name]} {...rest} onChange={this.handleChange} />;
  }

  renderInput(name, type, { ...rest }) {
    const { data, errors } = this.state;
    return (
      <React.Fragment>
        <Input name={name} value={data[name]} error={errors[name]} type={type} {...rest} onChange={this.handleChange} />
      </React.Fragment>
    );
  }

  renderPhoneInput(name, { ...rest }) {
    const { data, errors } = this.state;

    return (
      <PhoneInput
        className={'phoneInputFix ' + (errors[name] ? 'phoneError' : '')}
        country='BE'
        name={name}
        value={data[name]}
        autoComplete='on'
        {...rest}
        onChange={value => this.handlePhoneChange({ name: 'phone', value })}
      />
    );
  }
}

export default Form;
