import React from 'react';

import styles from '../../styles/loader.module.scss';

const Loader = ({ image, blue, ...rest }) => {
  return (
    <div className={`${styles.loader} ${blue ? styles.blue : ''}`} {...rest}>
      {image && <div className={styles.image}>{image}</div>}
      <div className={styles.ldsRoller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
