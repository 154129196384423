import React, { Component } from 'react';
import _ from 'lodash';
import styles from '../../../styles/gallery.module.scss';
import { Arrow } from '../../../images/icons';
import Loader from '../loader';
import Picture from '../picture';

class ImageGallery extends Component {
  state = {
    images: this.props.images,
    index: 0,
    visited: [0],
    loaded: [],
    direction: 1
  };

  componentDidMount = () => {
    this.interval = setInterval(() => this.autoSlide(), 3000);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  autoSlide = () => {
    const { index, images, visited } = this.state;
    let direction = this.state.direction;

    if (index + direction === images.length) direction = -1;
    else if (index === 0) direction = 1;
    this.setState({ index: index + direction, visited: _.union(visited, [index + direction]), direction });
  };

  moveSlide = step => {
    const { index, images, visited } = this.state;

    if (index !== 0 && step === -1) this.setState({ index: index + step, visited: _.union(visited, [index + step]) });
    else if (index !== images.length - 1 && step === 1)
      this.setState({ index: index + step, visited: _.union(visited, [index + step]) });
  };

  imageLoaded = index => {
    const { loaded } = this.state;
    this.setState({ loaded: _.union(loaded, [index]) });
  };

  showLightbox = image => {
    console.log(image);
  };

  render() {
    const { images, index, visited, loaded } = this.state;

    return (
      <div className='partner-block'>
        <div className={styles.container}>
          <div className={styles.content}>
            {/* <div className={`${styles.control} ${styles.left}`} onClick={() => this.moveSlide(-1)}>
              <Arrow />
            </div>
            <div className={`${styles.control} ${styles.right}`} onClick={() => this.moveSlide(1)}>
              <Arrow />
            </div>
            <div className={styles.bulletContainer}>
              {images.map((image, i) => (
                <div key={i} className={`${styles.bullet} ${i === index ? styles.active : ''}`}></div>
              ))}
            </div> */}
            <div
              className={styles.imageContainer}
              style={{ width: images.length * 100 + '%', marginLeft: -1 * index * 100 + '%' }}>
              {images.map((image, i) => (
                <div
                  key={image.original}
                  className={styles.innerContainer}
                  style={{ width: 100 / images.length + '%' }}
                  onClick={() => this.showLightbox(image.original)}>
                  <Loader style={_.includes(loaded, i) ? { opacity: 0 } : {}} blue={true} />
                  {_.includes(visited, i) ? (
                    <Picture
                      src={image.original}
                      ext='jpg'
                      alt='Slideshow'
                      extraClass={styles.image}
                      style={_.includes(loaded, i) ? { opacity: 1 } : {}}
                      onLoad={() => this.imageLoaded(i)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageGallery;
