import React from 'react';
import styles from './copyright.module.scss';

const Copyright = () => {
  const buildYear = 2019;
  const year = new Date(Date.now()).getUTCFullYear();

  return (
    <div className={styles.copyright}>
      <p className={styles.text}>
        Copyright &copy; {buildYear === year ? year : `${buildYear} - ${year}`}{' '}
        <a className={styles.text} href='https://linesntones.com' rel='author'>
          Lines &apos;n Tones BV
        </a>
        . All rights reserved.
      </p>
      <p className={styles.text}>Lines &apos;n Tones&reg; is a registered trademark.</p>
    </div>
  );
};

export default Copyright;
