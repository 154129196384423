import React, { Component } from 'react';
import styles from '../../styles/filter.module.scss';

class Filter extends Component {
  state = {
    active: false
  };

  activate() {
    const { active } = this.state;
    this.setState({ active: !active });
    this.props.switch({ filter: this.props.name }, !active);
  }

  render() {
    const { active } = this.state;
    return (
      <div className={styles.filter} onClick={() => this.activate()}>
        <div className={styles.checkbox + ' ' + (active ? styles.active : '')}></div>
        <p className='text'>{this.props.name}</p>
      </div>
    );
  }
}

export default Filter;
