import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LazyImage from '../lazyImage';
import styles from '../../../styles/partner.module.scss';

class Partner extends Component {
  state = {
    imageLoaded: false
  };

  render() {
    const { size, partner, show } = this.props;
    const { alias, name, banner } = partner;

    return (
      <div className='block' style={{ width: size.width, height: size.height }} onClick={() => show(partner)}>
        <Link to={`/${partner.alias}`}>
          <div className={styles.container}>
            <div className={styles.background}>
              <LazyImage svg={banner} src={`${alias}/banner`} ext='jpg' alt={name} />
            </div>
            <div className={styles.content}>
              <p className='small-title white light'>{name}</p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default Partner;
