import React from 'react';
import styles from '../../../styles/highlight.module.scss';

const PartnerHighlight = ({ children, light, ...rest }) => {
  return (
    <div className='partner-block' {...rest}>
      <div className={styles.container + ' ' + (light ? styles.light : '')}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default PartnerHighlight;
