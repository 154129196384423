import React from 'react';
import styles from '../../../styles/contactBlock.module.scss';
import { Website, Mail, Location, Facebook } from '../../../images/icons';
import { Mobile } from './../../../images/icons';

const Contact = ({ image, data }) => {
  return (
    <div className='partner-block'>
      <div className={styles.container}>
        <div className={styles.background}>
          <img src={image} alt='Banner' />
        </div>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <p className='large-title heavy small-margin'>Contact</p>
            {data.website && (
              <div className={styles.contentRow}>
                <Website />
                <a className='partner-text' href={`https://${data.website}`} rel='noopener noreferrer' target='_blank'>
                  {data.website}
                </a>
              </div>
            )}
            {data.mail && (
              <div className={styles.contentRow}>
                <Mail />
                <a className='partner-text' href={`mailto:${data.mail}`}>
                  {data.mail}
                </a>
              </div>
            )}
            {data.phones && (
              <div className={styles.contentRow}>
                <Mobile />
                <div className={styles.subrow}>
                  {data.phones.map(phone => (
                    <a key={phone.number} className='partner-text' href={`tel:${phone.link}`}>
                      {`${phone.number}`}
                      {phone.extra && (
                        <span className='partner-text small' style={{ marginLeft: '6px' }}>
                          {phone.extra}
                        </span>
                      )}
                    </a>
                  ))}
                </div>
              </div>
            )}
            <div className={styles.contentRow}>
              <Location />
              <div className={styles.subrow}>
                <p className='partner-text'>{data.street}</p>
                <p className='partner-text'>{data.city}</p>
              </div>
            </div>
            {data.facebook && (
              <div className={styles.socialRow}>
                <p className='partner-text' style={{ fontSize: '1.1rem' }}>
                  Follow us
                </p>
                <a className={styles.socialLink} href={data.facebook} rel='noopener noreferrer' target='_blank'>
                  <Facebook />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
