import React from 'react';
import LazyImage from '../lazyImage';
import styles from '../../../styles/logo.module.scss';

const Logo = ({ src, svg, alt }) => {
  return (
    <div className='partner-block'>
      <div className={styles.container}>
        <div className={styles.background}>
          <LazyImage src={src} ext='png' alt={alt} svg={svg} />
        </div>
      </div>
    </div>
  );
};

export default Logo;
