import React, { Component } from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import Search from '../common/search';
import Contact from './contact';
import Top from '../common/blocks/top';
import Highlight from '../common/blocks/highlight';
import Partner from '../common/blocks/partner';
import Copyright from '../copyright/copyright';
import { getPartners } from '../../services/partnerService';
import { toast } from 'react-toastify';
import { NavHashLink } from 'react-router-hash-link';

import '../../styles/main.module.scss';

class Home extends Component {
  state = {
    partners: getPartners(),
    size: 'small'
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.resized);
    this.resized();
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.resized);
  };

  resized = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      this.setState({ size: 'small' });
    } else if (innerWidth >= 768 && innerWidth < 992) {
      this.setState({ size: 'medium' });
    } else {
      this.setState({ size: 'large' });
    }
  };

  getSize = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      return { width: '100%', height: (innerWidth / 3) * 2 };
    } else if (innerWidth >= 768 && innerWidth < 992) {
      return { width: '50%', height: innerWidth / 3 };
    } else {
      return { width: '33.33333334%', height: (innerWidth / 9) * 2 };
    }
  };

  getContactSize = () => {
    const { innerWidth } = window;

    if (innerWidth < 768) {
      return { height: (innerWidth / 3) * 14 };
    } else if (innerWidth >= 768 && innerWidth < 992) {
      return { height: (innerWidth / 3) * 6 };
    } else {
      return { height: (innerWidth / 3) * 2 };
    }
  };

  search = (chars, filters) => {
    const inputFiltered = getPartners().filter(partner => partner.name.toLowerCase().includes(chars.toLowerCase()));
    const filterFiltered =
      filters.length === 0
        ? inputFiltered
        : inputFiltered.filter(partner => _.intersectionBy(partner.filters, filters, 'filter').length !== 0);

    this.setState({ partners: filterFiltered });
  };

  hideToasts = () => {
    toast.dismiss();
  };

  getContent = () => {
    const { partners, size } = this.state;

    const mapped = partners.map(partner => (
      <Partner key={partner.name} size={this.getSize()} partner={partner} show={() => this.hideToasts()} />
    ));

    const highlight = (
      <Highlight key='highlight' size={this.getSize()}>
        <NavHashLink
          smooth
          to='/#contact'
          style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <p className='title margin'>Uw zaak hier?</p>
          <p className='subtitle'>Neem contact</p>
          <p className='subtitle'>met ons op !</p>
        </NavHashLink>
      </Highlight>
    );

    let index = 0;
    let extra = 0;
    if (size === 'small') index = 1;
    else if (size === 'medium') {
      index = 2;
      extra = (partners.length + 2) % 2;
    } else if (size === 'large') {
      index = 3;
      extra = 3 - ((partners.length + 2) % 3);
    }

    const first = _.take(mapped, index);
    const rest = _.drop(mapped, index);
    const content = _.concat(first, highlight, rest);

    if (extra === 1)
      return _.concat(
        content,
        <Highlight key='highlight_1' size={this.getSize()}>
          <NavHashLink
            smooth
            to='/#contact'
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <p className='title margin'>Uw zaak hier?</p>
            <p className='subtitle'>Neem contact</p>
            <p className='subtitle'>met ons op !</p>
          </NavHashLink>
        </Highlight>
      );
    else if (extra === 2)
      return _.concat(
        content,
        <Highlight key='highlight_1' size={this.getSize()}>
          <NavHashLink
            smooth
            to='/#contact'
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <p className='title margin'>Uw zaak hier?</p>
            <p className='subtitle'>Neem contact</p>
            <p className='subtitle'>met ons op !</p>
          </NavHashLink>
        </Highlight>,
        <Highlight key='highlight_2' size={this.getSize()}>
          <NavHashLink
            smooth
            to='/#contact'
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center'
            }}>
            <p className='title margin'>Uw zaak hier?</p>
            <p className='subtitle'>Neem contact</p>
            <p className='subtitle'>met ons op !</p>
          </NavHashLink>
        </Highlight>
      );
    return content;
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Home &#124; Landen-Online</title>
        </Helmet>
        <div className='main'>
          <Top size={this.getSize()} />
          <Search search={this.search} />
          {this.getContent()}
          <Contact size={this.getContactSize()} />
        </div>
        <footer>
          <Copyright />
        </footer>
      </React.Fragment>
    );
  }
}

export default Home;
